<template>
  <div class="bg-white dark:bg-dark-1 rounded-md w-full h-full p-5 max-w-[1200px] hidden">

    <!--    WEBSITE PART BIGIN    -->
    <div class="dark:text-white text-black text-center text-2xl">Welcome to your dashboard</div>
    <div class="dark:text-white text-black text-justify text-xl my-9 hidden">
        This set up guide will help you get your page up and running. Please fill in all the required info to finish the Set Up Phase. After entering your company info, you can start adding products and prices. Please note, Step 3 and some other fields are skippable and can be filled in later.
        If you are unsure of how to fill in any field, just type something in temporarily and you can go back to edit it later in the Dashboard.
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <div class="col-span-2">
        <FormInput
            v-model="validate.domain.$model"
            :errors="validate.domain.$errors"
            :isIntro="false"
        >
          <template #label>
            <label class="form-label">
              Custom Domain
              <TipCustomDomain :content="domainContent" />
            </label>
          </template>
          <template #prepend>
            <div class="input-group-text">
              {{ locationCustomDomain }}
            </div>
          </template>
          <template #append>
            <div class="input-group-text">
              {{ hostnameCustomDomain }}
            </div>
          </template>
        </FormInput>
      </div>
      <div>
        <CustomSelect
            v-model="requestData.currency"
            :options="currencies"
            placeholder="Select currency"
            class="w-full"
            :errors="validate.currency.$errors"
        >
          <template #label>
            Type of currency
            <TipTypeCurrency />
          </template>
        </CustomSelect>
      </div>
    </div>
    <!--    WEBSITE PART END    -->

    <!--    EMAIL PART BEGIN    -->

    <div class="dark:text-white text-black text-center text-xl my-2 mt-10 hidden">Every time a customer creates a quote, they will be emailed a copy of the quote automatically.
      On this page, you can fill in the information that will be contained in the automatic email that users will receive after the quote is created.
    </div>
    <div class="rounded-md">
      <Title title="Sender Info" type-title="medium" class="!my-0 pb-2 hidden" />
      <div class="text-gray-600 pb-3 hidden">
        If you leave these fields empty, the default sender email, 'support@help.ez-estimate.com,' will be used automatically.<br>However, please note that emails sent to this address will not be forwarded to you.
      </div>
      <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div class="col-span-2">
          <FormInput
              v-model="validate.settings.emailSettings.mailInfo.sender.recipient_email.$model"
              :errors="validate.settings.emailSettings.mailInfo.sender.recipient_email.$errors"
              label="Sender Email"
              placeholder="Enter sender email"
          >
            <template #append>
              <div class="input-group-text">
                {{ domainRecipientEmail }}
              </div>
            </template>
            <template #tip>
              <RecipientTooltip />
            </template>
          </FormInput>
        </div>
        <div class="col-span-2">
          <FormInput
              v-model="validate.settings.emailSettings.mailInfo.sender.forwarder_email.$model"
              :errors="validate.settings.emailSettings.mailInfo.sender.forwarder_email.$errors"
              type="email"
              label="Forwarding Email"
              placeholder="Enter forwarding email"
          >
            <template #tip>
              <ForwarderTooltip />
            </template>
          </FormInput>
        </div>
      </div>
    </div>
<!--    <Line />
    <div class="rounded-md">
      <Title title="Company Info" type-title="medium" class="!my-0 pb-2" />
      <div class="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4">
        <div class="form-group my-2">
          <FormInput
              v-model="validate.settings.emailSettings.companyInfo.name.$model"
              :errors="validate.settings.emailSettings.companyInfo.name.$errors"
              label="Company name"
              placeholder="Enter company name"
          />
        </div>
        <div class="form-group my-2">
          <FormInput
              v-model="validate.settings.emailSettings.companyInfo.email.$model"
              :errors="validate.settings.emailSettings.companyInfo.email.$errors"
              label="Contact email"
              placeholder="Enter contact email"
          />
        </div>
        <div class="form-group my-2">
          <InputPhone
              v-model="validate.settings.emailSettings.companyInfo.phone.$model"
              :errors="validate.settings.emailSettings.companyInfo.phone.$errors"
              :default-val="validate.settings.emailSettings.companyInfo.phone.$model"
              id="company_phone"
              label="Company phone:"
              :index-z="49"
          />
        </div>
      </div>
    </div>
    <Line />
    <div class="rounded-md">
      <Title title="Email Info" type-title="medium" class="!my-0" />
      <div class="grid grid-cols-1 gap-4 mt-3">
        <div class="form-group">
          <FormInput
              v-model="validate.settings.emailSettings.mailInfo.title.$model"
              :errors="validate.settings.emailSettings.mailInfo.title.$errors"
              label="Mail title"
              placeholder="Enter mail title"
          />
        </div>
        <div class="form-group mt-1">
          <FormInput
              v-model="validate.settings.emailSettings.mailInfo.text.$model"
              :errors="validate.settings.emailSettings.mailInfo.text.$errors"
              label="Mail text"
              placeholder="Enter mail text"
          />
        </div>
      </div>
    </div>-->

    <!--    EMAIL PART END    -->

    <!--    DEFAULT FIELDS BEGIN    -->

    <div class="text-center text-xl mt-10 hidden">
      Since each project is a little different, we created customizable pricing rules. Rather than giving a customer one number, you can give your customer a range of costs in the "Quote price range" section ("-5%" and "20%" is a normal range). You can also create custom pricing rules that allow you to charge extra for small projects or give volume discounts for big ones. For example, if the project is less than or equal to 500 SQFT then add $250 to total cost.
    </div>
    <Title title="Quote price range" type-title="medium" class="mb-2 text-lg hidden">
      <template #tip>
        <TipRange />
      </template>
    </Title>
    <div class="rounded-md grid grid-cols-2 gap-4">
      <div class="form-group col-span-1">
        <FormInput
            v-model="validate.low.$model"
            :errors="validate.low.$errors"
            label="Low range(%):"
            placeholder="Enter low range(%)"
            type="number"
        >
          <template #prepend>
            <div
                class="input-group-text"
                v-text="'%'"
            />
          </template>
        </FormInput>
      </div>
      <div class="form-group col-span-1">
        <FormInput
            v-model="validate.high.$model"
            :errors="validate.high.$errors"
            label="High range(%):"
            placeholder="Enter high range(%)"
            type="number"
        >
          <template #prepend>
            <div
                class="input-group-text"
                v-text="'%'"
            />
          </template>
        </FormInput>
      </div>
    </div>

    <!--    DEFAULT FIELDS PART END    -->
  </div>
</template>

<script setup>
import { ref, computed, onMounted, toRefs, reactive, inject, defineExpose, getCurrentInstance} from 'vue';
import { useStore } from 'vuex';
import { maxLength, required, helpers, email, requiredIf } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core';
import axios from 'axios';
import { telInputValid } from '@mixins/composition/phoneMask';
import TipTypeCurrency from "@components/Tooltips/WebsiteStep/TipTypeCurrency.vue";
import TipCustomDomain from "@components/Tooltips/WebsiteStep/TipCustomDomain.vue";
import FormInput from "@components/UI/Input.vue";
import CustomSelect from "@components/UI/CustomSelect.vue";
import Title from "@components/Title.vue";
import Line from "@components/Line.vue";
import ForwarderTooltip from "@components/Tooltips/Sender/ForwarderTooltip.vue";
import RecipientTooltip from "@components/Tooltips/Sender/RecipientTooltip.vue";
import InputPhone from "@components/UI/InputPhone.vue";
import TipRange from "@components/Tooltips/TipRange.vue";

const { proxy } = getCurrentInstance();
const store = useStore();
const toast = inject('toast');
const currencies = ref([]);
const domainContent = `Your online estimator will have a custom domain/web address that is customizable. The format of the domain is www.________.${ window.location.hostname }. Type in what you want to have for the front part of the domain name; we suggest using your company name. For example, if your company is 'your name', type in 'yourname' and your estimator's web address will be www.yourname.${ window.location.hostname }`;
const locationCustomDomain = ref(window.location.protocol + '//');
const hostnameCustomDomain = ref('.' + window.location.hostname);
const userData = computed(() => store.getters['auth/user']);
const domainRecipientEmail = ref('@ezstimate.io');
const recipientEmails = ref([]);
const domain = computed({
  get() {
    return store.getters['website/domain'];
  },
  set(value) {
    store.commit('website/setDomain', value);
  }
});

const requestData = reactive({
  domain: domain.value ? domain.value : userData.value.company_name.replace(/[&\/\\#,+()$~%.'":*?<>{} _@]/g,''),
  companyName: (userData.value && userData.value.company_name) ?? '',
  currency: '',
  settings: {
    emailSettings: {
      companyInfo: {
        name: auth ? auth.company_name : '',
        email: auth ? auth.email : '',
        phone: auth ? auth.contact_number : '',
      },
      mailInfo: {
        title: 'Here is Your EZ-Estimate!',
        sender: {
          recipient_email: userData.value.company_name.replace(/[&\/\\#,+()$~%.'":*?<>{} _@]/g,''),
          forwarder_email: userData.value.email,
        },
        text: 'Thanks for using our online estimator! We have received your Quote Request and will be contacting you shortly.'
      }
    }
  },
  low: -5,
  high: 20
});
const domainRegex = (value) => /^[^./|\\]+$/.test(value);
const requestDataSender = computed(() => requestData.settings.emailSettings.mailInfo.sender);
const specialCharsPattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
const customValidation = (value) => {
  return !specialCharsPattern.test(value);
}
const unique = (value) => {
  return !recipientEmails.value.includes(value);
}
const rules = {
  domain: {
    required,
    maxLength: maxLength(40),
    matchRegex: helpers.withMessage('Domain must not contain special symbols', domainRegex)
  },
  companyName: { required, maxLength: maxLength(200) },
  currency: { required },
  settings: {
    emailSettings: {
      companyInfo: {
        name: {  },
        email: { email },
        phone: { phone: helpers.withMessage('Phone Number is invalid', telInputValid) }
      },
      mailInfo: {
        title: { required },
        sender: {
          recipient_email: {
            maxLength: maxLength(40),
            /*withParams: helpers.withParams({ type: 'unique' }, value => unique(value)),
            withMessage: helpers.withMessage('The email has already been taken.', unique),*/
            withParamsSpecialChars: helpers.withParams({ type: 'noSpecialChars' }, value => customValidation(value)),
            withMessageSpecialChars: helpers.withMessage('The sender email cannot contain special chars.', customValidation),
            required: requiredIf(() => {
              return (requestDataSender.value.forwarder_email === '' && requestDataSender.value.recipient_email === '' && requestDataSender.value.forwarder_email === null && requestDataSender.value.recipient_email === null) ? false : requestDataSender.value.forwarder_email !== '' && requestDataSender.value.forwarder_email !== null;
            }),
          },
          forwarder_email: {
            maxLength: maxLength(40),
            required: requiredIf(() => {
              return (requestDataSender.value.forwarder_email === '' && requestDataSender.value.recipient_email === '' && requestDataSender.value.forwarder_email === null && requestDataSender.value.recipient_email === null) ? false : requestDataSender.value.recipient_email !== '' && requestDataSender.value.recipient_email !== null;
            }),
          },
        },
        text: { required }
      }
    }
  },
  low: { required, maxLength: maxLength(8) },
  high: { required, maxLength: maxLength(8) },
}
const validate = useVuelidate(rules, toRefs(requestData));

const saveAll = async () => {
  validate.value.$touch()

  if (!validate.value.$invalid) {
    try {
      await saveWebsiteData(),
      await saveEmailData(),
      await saveRules()

      toast({
        text: 'Your settings are saved!',
        type: 'success'
      })
    } catch (e) {
      proxy.$errorHandler(e)
      toast({
        text: 'Something went wrong!',
        type: 'danger'
      })
    }
  }
}
defineExpose({
  saveAll
});

const saveRules = () => {
  let object = {
    range: {
      low: requestData.low,
      high: requestData.high
    },
    website_id: requestData.website_id
  }

   axios.post('/api/pricing-rules', object)
     .then(() => {})
     .catch((e) => {
      throw e
    })
}
const saveWebsiteData = async () => {
  await axios.post('/api/website', {
    user_id: userData.value.id,
    code: requestData.domain.toLowerCase(),
    currency: requestData.currency ?? currencies.value[0].value,
    status: 0,
    company_name: requestData.companyName.toLowerCase()
  }).then((response) => {
    store.commit('website/setWebsite', response.data.website);
    requestData.website_id = response.data.website.id
  }).catch((e) => {
    throw e
  })
}

const saveEmailData = () => {
  requestData.settings.websiteSettings = {
    footer: {
      email: requestData.settings.emailSettings.companyInfo.email,
      contact_phone: requestData.settings.emailSettings.companyInfo.phone,
      address: '',
      facebook: '',
      youtube: '',
      twitter: '',
      instagram: ''
    },
    about_us: {
      title: 'Welcome to our Online Estimator Tool!',
      description: 'Outside renovation projects don\'t happen too often, so it is tough to know how much it should cost. Our tool will help you get an immediate ballpark on how much your project will cost, with only a few clicks of a button!' +
          '\n' +
          'On the tool, you will be able to:\n' +
          '1) Measure how large the area is that you want to renovate (without needing a tape measure)\n' +
          '2) Choose from the different options and upgrades we offer\n' +
          '3) Get an immediate ballpark price for all of the options you are interested in\n' +
          '\n' +
          'If you want to see a quick tutorial on our tool, check out the video below!',
    },
    contact_us: {
      email: requestData.settings.emailSettings.companyInfo.email,
    },
    callback: {
      button_text: '',
      button_link: ''
    },
    onlyQuote: true
  }

  axios.post('/api/settings/', requestData)
    .then(() => {})
    .catch((e) => {
      throw e
    })
}

const getSelfWebsite = async () => {
  const response = await axios.get('/api/self-website').catch((e) => {})

  if (!response?.data) return

  await store.commit('website/setWebsite', response.data)
  requestData.domain = response.data?.code;
  requestData.currency = response.data?.currency?.value;
  requestData.website_id = response.data?.id
}

const getData = async () => {
  const { data } = await axios.get('/api/settings/', { params: { settings: [ 'emailSettings' ] } });

  if (data.settings[0] && data.settings[0]['value']) {
    let parseSettings = JSON.parse(data.settings[0]['value']);

    requestData.settings.emailSettings.mailInfo = parseSettings.mailInfo;
    requestData.settings.emailSettings.companyInfo = parseSettings.companyInfo;
  }
}

const getRanges = async () => {
  const { data } = await axios.get('/api/pricing-rules?website_id=' + requestData.website_id)
  requestData.high = data.lowHighRanges?.high ?? 20
  requestData.low = data.lowHighRanges?.low ?? -5
}

onMounted(async () => {
  await getSelfWebsite()
  await getData()
  await getRanges()

  await axios.get('api/currency').then(({ data }) => {
    currencies.value = data;
    requestData.currency = requestData.currency != '' ? requestData.currency : data[0].value;
  });

  axios.get('/api/get-recipient-emails').then(({ data }) => {
    recipientEmails.value = data
  });
});
</script>

<style lang="scss" scoped>
.domain_label {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  box-orient: vertical;
  word-break: break-all;
  max-width: 240px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.rule-unit{
  max-width: 70px;
  right: 0;
}
</style>
