<template>
    <fieldset :disabled="loading">
        <slot name="label">
            <label
                v-if="label.length > 0"
                :class="['form-label text-left w-full', classMapLabel]"
            >
                {{ label }}
                <slot name="tip" />
            </label>
        </slot>
        <div class="input-group">
            <slot name="prepend" />
            <input
                v-maska="mask ? [mask, mask] : null"
                :id="id"
                :autofocus="autofocus"
                :type="type"
                :class="classes"
                :placeholder="placeholder"
                :readonly="readonly"
                :step="step"
                :value="modelValue"
                :required="required"
                @input="(event) => emit('update:modelValue', event.target.value)"
            >
            <slot name="append" />
        </div>

        <template v-if="errors && errors.length > 0">
            <div
                v-for="(error, index) in errors"
                :key="index"
                class="text-theme-6 mt-2"
            >
                {{ error.$message }}
            </div>
        </template>
    </fieldset>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { useStore } from "vuex";


const props = defineProps({
    label: {
        type: String,
        required: false,
        default: '',
    },
    placeholder: {
        type: [ String, Number ],
        required: false,
        default: '',
    },
    type: {
        type: String,
        required: false,
        default: 'text',
    },
    errors: {
        type: Array,
        required: false,
        default: [],
    },
    modelValue: {
        type: [ String, Number ],
        default: null,
    },
    classMap: {
        type: [String, Array],
        required: false,
    },
    classMapLabel: {
        type: String,
        required: false,
    },
    focus: {
        type: Boolean,
        default: false,
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        default: '',
    },
    id: {
        type: String,
        default: '',
    },
    tooltip: {
        type: String,
        required: false
    },
    tabIndex: {
        type: String,
        required: false
    },
    step: {
        type: [Number, String],
        default: null,
    },
    isIntro:{
        type: Boolean,
        default: false
    },
    mask: {
        type: String,
        default: ''
    },
    readonly: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    },
});
const store = useStore();
const emit = defineEmits(['update:modelValue']);
const loading = computed(() => store.getters['main/loading']);
const classes = computed(() => [ 'form-control py-2 px-4 block', props.classMap, {'intro-x': props.isIntro}, {'!border-theme-40': props.errors && props.errors.length > 0} ]);

</script>
