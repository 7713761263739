<template>
    <div>
        <div
            :id="'update-' + currentItem.id"
            class="modal attr"
            style="z-index: 9998 !important;"
            tabindex="-1"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body p-4">
                        <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal"></i>
                        <form @submit="saveAttribute">
                            <div class="form-group product-modal-image-wrapper">
                                <ImageUploader
                                    :url="itemImageUrl"
                                    :remove-file="removeFile"
                                    @fileUploaded="fileUploaded"
                                />
                            </div>
                            <div class="form-group mt-5">
                                <FormInput
                                    v-model="validate.value.$model"
                                    :errors="validate.value.$errors"
                                    type="text"
                                    label="Name:"
                                    placeholder="Enter name"
                                />
                            </div>

                            <ChooseMetricType
                                v-if="type == 1"
                                v-model="validate.metric_id.$model"
                                :errors="validate.metric_id.$errors"
                            />

                            <div class="form-group mt-5">
                                <FormTextarea
                                    v-model="validate.description.$model"
                                    :errors="validate.description.$errors"
                                    type="text"
                                    label="Description:"
                                    placeholder="Enter description"
                                />
                            </div>
                            <div v-if="itemLayerInclude.includes(type)" class="form-group mt-5 flex items-center gap-2">
                                <InputColor
                                    v-model="validate.color.$model"
                                    :errors="validate.color.$errors"
                                />
                                <div>
                                    Service Color <TipColor />
                                </div>
                            </div>
                            <div v-if="itemTypeInclude.includes(type)" class="form-group mt-5">
                                <div v-if="props.type != 5" class="space-y-4 mt-4 mb-4">
                                  <label class="block" v-for="commonType in itemCommonTypes" :key="'common-type-' + commonType.id">
                                    <input type="checkbox" v-model="commonType.is_active" class="form-checkbox">
                                    {{ commonType.title }}
                                    <!-- ********** presetsize block -->
                                    <div v-if="commonType.id == 3 && commonType.is_active" class="mt-2">
                                      <div v-for="(size) in commonType.config?.sizes" :key="'presize-' + size" class="flex mt-2 mb-2 w-full justify-center gap-4 items-center">
                                          <div class="text-base font-medium" style="width: 100px;">{{ size.title }}</div>
                                          <!-- actions -->
                                          <div class="flex items-center h-full justify-evenly gap-4">
                                              <a @click.prevent.stop="showModal('#' + idPreSetModal, size?.id ?? 0)" class="block col-span-12 sm:col-span-5 2xl:col-span-1 z-50" data-toggle="modal">
                                                  <svg width="30" height="30" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M24.4029 4.10388C22.1132 1.81267 19.0999 0.386302 15.8764 0.0678461C12.6529 -0.25061 9.41876 0.558548 6.72503 2.35743C4.03129 4.15632 2.04468 6.83362 1.10373 9.9331C0.162769 13.0326 0.325694 16.3624 1.56474 19.3553C2.80378 22.3481 5.04227 24.8186 7.89875 26.346C10.7552 27.8733 14.0529 28.3629 17.2299 27.7312C20.4069 27.0996 23.2666 25.3859 25.3216 22.8821C27.3767 20.3783 28.4999 17.2394 28.5 14.0002C28.4999 10.2888 27.0262 6.72925 24.4029 4.10388ZM11.9197 15.5546H17.101V17.6272H11.9197V15.5546ZM9.84715 23.8344V20.736H19.1736V23.8318C17.7156 24.5206 16.1232 24.878 14.5107 24.8785C12.8981 24.8789 11.3055 24.5224 9.84715 23.8344ZM22.2824 21.6155V20.2178C22.2825 19.6206 22.0763 19.0417 21.6986 18.579C21.3209 18.1164 20.795 17.7984 20.2098 17.679V15.0365C20.2094 14.4659 20.0206 13.9115 19.6728 13.4592C19.325 13.0069 18.8376 12.6821 18.2863 12.5352L17.0065 7.93807C16.8549 7.39252 16.5288 6.91166 16.078 6.56905C15.6272 6.22644 15.0766 6.04094 14.5104 6.04094C13.9442 6.04094 13.3935 6.22644 12.9427 6.56905C12.492 6.91166 12.1658 7.39252 12.0143 7.93807L10.7345 12.5352C10.1831 12.6821 9.69575 13.0069 9.34793 13.4592C9.00011 13.9115 8.81133 14.4659 8.81088 15.0365V17.6816C8.22616 17.8009 7.70057 18.1185 7.32294 18.5806C6.94532 19.0427 6.73882 19.6211 6.73834 20.2178V21.6129C5.23886 20.0825 4.22457 18.1435 3.82267 16.0389C3.42078 13.9344 3.64915 11.7581 4.47917 9.78279C5.30918 7.80753 6.70389 6.12127 8.4884 4.93548C10.2729 3.74968 12.3678 3.11712 14.5104 3.11712C16.6529 3.11712 18.7478 3.74968 20.5323 4.93548C22.3168 6.12127 23.7115 7.80753 24.5416 9.78279C25.3716 11.7581 25.6 13.9344 25.1981 16.0389C24.7962 18.1435 23.7819 20.0825 22.2824 21.6129V21.6155Z" fill="#3EAF69"></path>
                                                  </svg>
                                              </a>
                                              <a @click.prevent.stop="deletePresetSize(size)" class="block col-span-12 sm:col-span-5 2xl:col-span-1 z-50" data-toggle="modal">
                                                  <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M5 27.5C4.175 27.5 3.4685 27.206 2.8805 26.618C2.2925 26.03 1.999 25.324 2 24.5V5H0.5V2H8V0.5H17V2H24.5V5H23V24.5C23 25.325 22.706 26.0315 22.118 26.6195C21.53 27.2075 20.824 27.501 20 27.5H5ZM20 5H5V24.5H20V5ZM8 21.5H11V8H8V21.5ZM14 21.5H17V8H14V21.5Z" fill="#C9000E"></path>
                                                  </svg>
                                              </a>
                                          </div>
                                      </div>
                                      <div class="flex w-full items-center h-full justify-evenly mb-4">
                                        <MainButton
                                            title="Add a preset size"
                                            size-button="small"
                                            @click="showModal('#' + idPreSetModal)"
                                        />
                                      </div>
                                    </div>
                                    <!-- ************* -->
                                    <!-- ********** tiers block -->
                                    <TiersBlock
                                      v-if="commonType.title == 'Tiers' && commonType.is_active"
                                      :label="labelPrice.replace('Price ', '')"
                                      :optionTiers="optionTiers"
                                      :price-type="commonType.config.price_type"
                                      @updateTiers="updateTiers"
                                      @updateTiersPriceType="updateTiersPriceType"
                                    />
                                    <!-- ************* -->
                                  </label>
                                </div>

                                <div class="flex items-center gap-4" v-if="!itemCommonTypes.find(el => el.title == 'Tiers').is_active">
                                    <div>
                                        <label>
                                            {{ labelPrice }}
                                            <TipAttribute :type-attribute="type" :label="labelPrice" />
                                        </label>
                                    </div>
                                    <div v-if="itemTypeSqft.includes(type)">
                                        <MainButton
                                            title="How to calculate"
                                            size-button="small"
                                            @click="calculate.show=!calculate.show"
                                        />
                                    </div>
                                </div>
                                <div class="mt-2" v-if="!itemCommonTypes.find(el => el.title == 'Tiers').is_active">
                                    <FormInput
                                        v-model.lazy="validate.price.$model"
                                        :errors="validate.price.$errors"
                                        :step="0.0001"
                                        type="number"
                                        placeholder="0.00"
                                    >
                                        <template #prepend>
                                            <div
                                                v-text="$store?.getters['website/website']?.currency?.sign ?? '$'"
                                                class="input-group-text"
                                            />
                                        </template>
                                    </FormInput>
                                </div>

                              <div v-if="calculate.show" class="mt-4">
                                    <Select
                                        v-model="calculate.type"
                                        :options="[{name: 'Plants formula', value: 'PS'}, {name: 'Mulch/Gravel Formula', value: 'MGF'}]"
                                        label="Formula"
                                        placeholder="Select Formula"
                                        class-map="w-full min-w-[10rem]"
                                    />
                                    <template v-if="calculate.type">
                                        <div class="grid grid-cols-2 gap-4">
                                            <div class="mt-2">
                                                <FormInput
                                                    v-model="calculate.cost"
                                                    :step="0.01"
                                                    :label="calculate.type === 'PS' ? 'Plan Cost' : 'Cost/yard'"
                                                    type="number"
                                                />
                                            </div>
                                            <div class="mt-2">
                                                <FormInput
                                                    v-model="calculate.square"
                                                    :step="0.01"
                                                    :label="calculate.type === 'PS' ? 'Space between plants (ft)' : 'Mulch/Gravel Depth (Inches)'"
                                                    type="number"
                                                />
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <template v-if="calculate.cost > 0 && calculate.square > 0">
                                            <span
                                                @click="copy(calculate.result)"
                                                class="item-copy"
                                            >
                                                {{ labelPrice }} = ${{ calculate.result }}
                                            </span>
                                            </template>
                                        </div>
                                    </template>
                                </div>

                              <div class="flex items-center gap-4 mt-4" v-if="!itemCommonTypes.find(el => el.title == 'Tiers').is_active">
                                <div>
                                  <label>
                                    Minimum
                                  </label>
                                </div>
                              </div>
                              <div class="mt-2" v-if="!itemCommonTypes.find(el => el.title == 'Tiers').is_active">
                                <FormInput
                                    v-model.lazy="validate.min_price.$model"
                                    :errors="validate.min_price.$errors"
                                    :step="0.01"
                                    :min="0.01"
                                    type="number"
                                    placeholder="0.00"
                                >
                                  <template #prepend>
                                    <div
                                        v-text="$store?.getters['website/website']?.currency?.sign ?? '$'"
                                        class="input-group-text"
                                    />
                                  </template>
                                </FormInput>
                              </div>
                            </div>
                            <div class="flex justify-center my-2">
                                <MainButton
                                    title="Update"
                                    type="submit"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <PreSetSizeModal
            :optionPreSizes="itemCommonTypes[2]?.config?.sizes"
            :currentSize="currentSize"
            :units="labelPrice.replace('Price ', '')"
            @preSetSize="preSetSize"
            :id="idPreSetModal"
        />
    </div>
</template>

<script setup>
import { defineProps, defineEmits, watch, ref, reactive, toRaw, computed, inject } from 'vue'
import { useStore } from 'vuex';
import { helpers, maxLength, minLength, minValue, numeric, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { copy } from "@scripts/plugins/functions";
import cash from 'cash-dom/dist/cash';
import ImageUploader from '@components/UI/ImageUploader';
import TipAttribute from "@components/Tooltips/TipAttribute.vue";
import TipColor from "@components/Tooltips/TipColor.vue";
import InputColor from "@components/UI/InputColor.vue";
import FormInput from "@components/UI/Input.vue";
import MainButton from "@components/Button/MainButton.vue";
import Select from "@components/UI/Select.vue";
import ChooseMetricType from "@components/Page/Attribute/ChooseMetricType.vue";
import FormTextarea from "@components/UI/FormTextarea.vue";
import PreSetSizeModal from './PreSetSizeModal.vue';
import TiersBlock from './TiersBlock.vue'
const toast = inject('toast');

const props = defineProps({
    currentItem: {
        type: Object,
        default: () => {
            return {
              value: '',
              description: '',
              price: null,
              min_price: null,
              color: null,
              metric_id: ''
            }
        }
    },
    type: {
        type: Number,
        default: null
    },
    isRefresh: {
        type: Boolean,
        default: false
    },
    labelPrice: {
        type: String,
        default: 'SQFT'
    }
});
const emit = defineEmits([ 'saveAttribute' ]);
const store = useStore();
const formData = new FormData();
const removeFile = ref(false);
const itemImageUrl = ref('');
const itemTypeInclude = [2, 3, 5, '2', '3', '5'];
const itemLayerInclude = [1, '1'];
const itemTypeSqft = [ 2, 3, '2', '3' ];

const optionTiers = ref([])

const currentSize = ref({
  id: 0,
  title: '',
  size: 0.00,
  image: null,
  isUpdate: false
})

const showModal = (id, itemId = 0) => {
    if (id == '#' + idPreSetModal) {
      if (itemId != 0) {
        let index = 0;
        for (const type of itemCommonTypes.value) {
          if (type.attribute_common_type_id == 3) {
            const newSize = JSON.parse(JSON.stringify(itemCommonTypes.value[index].config.sizes.filter(el => el.id == itemId)))[0]
            currentSize.value.id = newSize.id
            currentSize.value.title = newSize.title
            currentSize.value.size = newSize.size
            currentSize.value.image = newSize.image
          }
          index++;
        }
      } else {
        currentSize.value.id = 0
        currentSize.value.title = ''
        currentSize.value.size = 0
        currentSize.value.image = null
      }
    }
  
    cash(id).modal('show')
}

const calculate = ref({
    show: false,
    cost: 0,
    square: 0,
    type: null,
    result: 0
});
const requestData = reactive({
    value: '',
    description: '',
    price: null,
    min_price: null,
    color: null,
    metric_id: '',
});
const generalCommonTypes = store.getters['general/getAttributeCommonTypes']
const itemCommonTypes = ref(generalCommonTypes.map(type => ({
  ...type,
  is_active: true,
  config: type.id == 3 ? { sizes: [] } : (type.id == 4 ? { tiers: [] } : {}),
  attribute_common_type_id: type.id
})))

const isTiersActive = computed(() => itemCommonTypes.value.find(el => el.title == 'Tiers').is_active)

const rules = {
    value: { required, maxLength: maxLength(40) },
    description: { maxLength: maxLength(80) },
    price: !itemLayerInclude.includes(props.type) && !isTiersActive ? {
        required, numeric, minValue: minValue(0.0001),
        customMaxLength: helpers.withMessage('The maximum length allowed is 9', value => {
            let priceWithoutDecimal = value;
            let priceSlice = value.slice(-5);

            if (priceSlice.includes(",") || priceSlice.includes(".")) {
                priceWithoutDecimal = value.slice(0, -5);
            }

            return priceWithoutDecimal.length <= 9;
        }),
    } : {},
    min_price: { minValue: minValue(0.01) },
    color: itemLayerInclude.includes(props.type) ? { required, minLength: minLength(4) } : {},
    metric_id: props.type == 1 ? { required } : {},
}
const validate = useVuelidate(rules, requestData);

const fileUploaded = (file) => {
    formData.append('file', file);
    removeFile.value = false;
}

const saveAttribute = (e) => {
    e.preventDefault();
    validate.value.$touch();
    
    if (!validate.value.$invalid) {
        itemCommonTypes.value.forEach((type, index) => {
          formData.append('attribute_common_types[]', JSON.stringify(type))
        })

        formData.append('value', requestData.value)
        formData.append('type', props.type)
        formData.append('price', requestData.price)

        if (requestData.min_price) {
          formData.append('min_price', requestData.min_price)
        }

        formData.append('description', requestData.description)
        formData.append('color', requestData.color)
        formData.append('metric_id', requestData.metric_id)

        if (!formData.has('file') && props.currentItem.media_id !== null) {
            formData.append('media_id', requestData.description)
        }
        cash('#update-' + props.currentItem.id).modal('hide');
        removeFile.value = true;
        emit('saveAttribute', formData);
        formData.delete('value');
        formData.delete('type');
        formData.delete('price');
        formData.delete('min_price');
        formData.delete('description');
        formData.delete('color');
        formData.delete('metric_id');
        itemCommonTypes.value.forEach((type, index) => {
          formData.delete('attribute_common_types[]', JSON.stringify(type))
        })
        validate.value.$reset();
        clearCurrentPreSetSizes();
        clearCurrentTiers();

      toast({
        text: 'Attribute updated successfully',
        type: 'success'
      });
    }
}

const clearCurrentPreSetSizes = () => {
    for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
        if (itemCommonType.attribute_common_type_id == 3) {
            itemCommonTypes.value[index].config.sizes = []
        }
    }
}

const clearCurrentTiers = () => {
    for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
        if (itemCommonType.attribute_common_type_id == 4) {
            itemCommonTypes.value[index].config.tiers = []
        }
    }
}

const calculateFormula = () => {
    if (calculate.value.type === 'PS') {
        calculate.value.result = calculate.value.cost / calculate.value.square;
    } else {
        calculate.value.result = calculate.value.cost / (324 / calculate.value.square);
    }

    calculate.value.result = Number(calculate.value.result.toFixed(2));
}

const deletePresetSize = (size) => {
    for (let [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
        if (itemCommonType.attribute_common_type_id == 3) {
            itemCommonTypes.value[index].config.sizes = itemCommonTypes.value[index].config.sizes.filter(el => el.id != size.id)
        }
    }
}

const preSetSize = (data) => {
  for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
    if (itemCommonType.attribute_common_type_id == 3) {
        itemCommonTypes.value[index].config.sizes = data
    }
  }
}

const updateTiersPriceType = (priceType) => {
  for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
    if (itemCommonType.id == 4) {
      itemCommonTypes.value[index].config.price_type = priceType
    }
  }
}

const updateTiers = (tiers) => {
  optionTiers.value = tiers

  for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
    if (itemCommonType.id == 4) {
      itemCommonTypes.value[index].config.tiers = optionTiers.value
    }
  }
}

const generateId = () => {
  return (
      "_" +
      Math.random()
          .toString(36)
          .substr(2, 9)
  );
}
const idPreSetModal = 'preSetSizeAdminModal' + generateId()

watch(
    () => calculate.value,
    (calculate) => {
        if (calculate.cost > 0 && calculate.square > 0) {
            calculateFormula();
        }
    },
    {
        deep: true
    }
);

watch(() => props.currentItem, () => {
    requestData.value = props.currentItem.value;
    requestData.price = props.currentItem.price;
    requestData.min_price = props.currentItem.min_price;
    requestData.description = props.currentItem.description;
    requestData.color = props.currentItem.color;
    requestData.metric_id = props.currentItem.attribute_metric;
    itemImageUrl.value = props.currentItem?.media?.url;
  
    if (props.currentItem.commons?.length) {
      itemCommonTypes.value = props.currentItem.commons.map(common => {
        return {
          id: common.common_type.id,
          title: common.common_type.title,
          is_active: common.is_active,
          config: common.config,
          attribute_common_type_id: common.common_type.id
        }
      })
      
      // Extend existing common types which have tiers
      if (itemCommonTypes.value && !itemCommonTypes.value.find(el => el.title === 'Tiers')) {
        const allCommonTypes = store.getters['general/getAttributeCommonTypes']
        const tiersCommonType = allCommonTypes.find(el => el.title === 'Tiers')

        itemCommonTypes.value.push({
          id: tiersCommonType.id,
          title: tiersCommonType.title,
          is_active: false,
          config: {
            tiers: [],
            price_type: 'flat fee'
          },
          attribute_common_type_id: tiersCommonType.id
        })
      }
    }

    for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
      if (itemCommonType.id == 4) {
        optionTiers.value = itemCommonTypes.value[index].config.tiers
      }
    }
    
    validate.value.$reset();

    calculate.value.show = false;
    calculate.value.result = 0;
    calculate.value.cost = 0;
    calculate.value.square = 0;
    calculate.value.type = null;

    removeFile.value = true;
}, {
    deep: true
});

// pre-sizes if true -> tiers false
watch(() => itemCommonTypes.value[2]?.is_active, (value) => {
  if (itemCommonTypes.value[2]?.is_active) {
    itemCommonTypes.value[3].is_active = false
  }
});

// tries if true -> pre-sizes false
watch(() => itemCommonTypes.value[3]?.is_active, (value) => {
  if (itemCommonTypes.value[3]?.is_active) {
    itemCommonTypes.value[2].is_active = false
  }
});

</script>
<style lang="scss" scoped>
:deep(.height-img-wrapper) {
    height: 10rem;
    width: 10rem;
}

.attr {
    z-index: 9998 !important;
}
</style>
