<template>
  <GeneralLayout>
    <LogoutItem />

    <div class="flex h-screen justify-center items-center">
      <div class="mx-auto mb-auto mt-[24px] block-width">
        <GuideMainForm
          ref="guideForm"
        />
        <GuideServices
            ref="guideServices"
        />

        <div class="w-full flex justify-center">
          <MainButton
              title="Next"
              class-map="mt-10 mb-10"
              :typeButton="loading ? 'disabled' : ''"
              @click="save"
          />
        </div>
      </div>
    </div>
  </GeneralLayout>
</template>

<script setup>
import MainButton from "@components/Button/MainButton.vue";
import { ref, onMounted, watch, computed, getCurrentInstance } from 'vue';
import LogoutItem from '@components/LogoutItem.vue';
import GeneralLayout from "../../Layouts/GeneralLayout.vue";
import GuideMainForm from './GuideMainForm.vue'
import GuideServices from './GuideServices.vue'
import { useStore } from 'vuex'
import { useRouter} from 'vue-router'
import axios from 'axios'

const router = useRouter()
const guideForm = ref(null)
const guideServices = ref(null)
const loading = ref(false)

const store = useStore()

async function save() {
  loading.value = true

  try {
    await guideForm.value.saveAll();
    await guideServices.value.saveServices();
    loading.value = false

    store.commit('auth/setUser', { ...store.getters['auth/user'], hasAttributes: true });

    router.push({ path: '/quide-callendly' })
  } catch (e) {
    loading.value = false
  }
}

onMounted(() => {
  const user = store.getters['auth/user']
  if (!user) {
    axios.get('/api/v1/user').then((res) => {
      store.commit('auth/setUser', res.data.data);
    });
  }
})
</script>

<style scoped>

</style>
