<script setup>
import { onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const calendlyLink = ref('https://calendly.com/ezestimate/success-call')

onMounted(() => {
  const user = store.getters['auth/user']
  if (user?.company_name && user?.email) {
    calendlyLink.value = `https://calendly.com/ezestimate/success-call?name=${user.company_name}&email=${user.email}`
  }
})
</script>

<template>
  <div class="banner-wrapper">
    <span>Need help? We can build your page for you (free)!</span>
    <a :href="calendlyLink" target="_blank">Sign Up</a>
    <span> here for a call here!</span>
  </div>
</template>



<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .banner-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    span {
      text-align: center;
    }
  }
}

.banner-wrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
  background-color: #f0fff0; /* Light green background */
  padding: 10px; /* Padding around the content */
  font-family: Arial, sans-serif; /* Font styling */
}

.banner-wrapper a {
  color: green; /* Link color */
  font-weight: bold; /* Bold the link */
  text-decoration: none; /* Remove underline */
}

span {
  color: #333; /* Text color */
  font-weight: bold;
}

.banner-wrapper a:hover {
  text-decoration: underline; /* Add underline on hover */
}
</style>
