<template>
    <div>
        <label
            v-if="label.length > 0"
            :for="id"
            class="form-label"
        >
            {{ label }}
            <slot name="tip" />
        </label>
        <textarea
            :autofocus="autofocus"
            :class="classes"
            :placeholder="placeholder"
            :title="title"
            :value="modelValue"
            :cols="cols"
            :rows="rows"
            @input="(event) => emit('update:modelValue', event.target.value)"
        />

        <template v-if="errors && errors.length > 0">
            <div
                v-for="(error, index) in errors"
                :key="index"
                class="text-theme-6 mt-2"
            >
                {{ error.$message }}
            </div>
        </template>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps({
    label: {
        type: String,
        required: false,
        default: '',
    },
    placeholder: {
        type: [ String, Number ],
        required: false,
        default: '',
    },
    type: {
        type: String,
        required: false,
        default: 'text',
    },
    errors: {
        type: Array,
        required: false,
        default: '',
    },
    modelValue: {
        type: [ String, Number ],
        default: null,
    },
    classMap: {
        type: String,
        required: false,
        default: '',
    },
    focus: {
        type: Boolean,
        default: false,
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
        default: '',
    },
    tooltip: {
        type: String,
        required: false
    },
    id: {
        type: String,
        default: '',
    },
    cols: {
        type: Number,
        default: 4
    },
    rows: {
        type: Number,
        default: 4
    },
});

const emit = defineEmits(['update:modelValue']);

const classes = ['form-control', props.classMap];
</script>

<style scoped>

</style>
