<template>
    <div class="custom-grid">
        <template v-for="(item, index) in items">
            <div @click="selectItem(item)">
                <div
                    v-show="item.type !== '4'"
                    :class="['flex-none relative block before:block before:w-full rounded-lg before:pt-[100%] attribute-item zoom-in', {active: item.active}]"
                >
                    <div class="absolute top-0 left-0 w-full h-full image-fit">
                        <div v-if="!store.getters['auth/isSales'] && item.active" class="absolute flex w-full items-center h-full justify-evenly">
                            <a
                                :key="index"
                                :data-target="'#update-' + item.id"
                                href="javascript:void(0);"
                                class="block col-span-12 sm:col-span-5 2xl:col-span-1 z-50"
                                data-toggle="modal"
                            >
                                <svg width="30" height="30" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24.4029 4.10388C22.1132 1.81267 19.0999 0.386302 15.8764 0.0678461C12.6529 -0.25061 9.41876 0.558548 6.72503 2.35743C4.03129 4.15632 2.04468 6.83362 1.10373 9.9331C0.162769 13.0326 0.325694 16.3624 1.56474 19.3553C2.80378 22.3481 5.04227 24.8186 7.89875 26.346C10.7552 27.8733 14.0529 28.3629 17.2299 27.7312C20.4069 27.0996 23.2666 25.3859 25.3216 22.8821C27.3767 20.3783 28.4999 17.2394 28.5 14.0002C28.4999 10.2888 27.0262 6.72925 24.4029 4.10388ZM11.9197 15.5546H17.101V17.6272H11.9197V15.5546ZM9.84715 23.8344V20.736H19.1736V23.8318C17.7156 24.5206 16.1232 24.878 14.5107 24.8785C12.8981 24.8789 11.3055 24.5224 9.84715 23.8344ZM22.2824 21.6155V20.2178C22.2825 19.6206 22.0763 19.0417 21.6986 18.579C21.3209 18.1164 20.795 17.7984 20.2098 17.679V15.0365C20.2094 14.4659 20.0206 13.9115 19.6728 13.4592C19.325 13.0069 18.8376 12.6821 18.2863 12.5352L17.0065 7.93807C16.8549 7.39252 16.5288 6.91166 16.078 6.56905C15.6272 6.22644 15.0766 6.04094 14.5104 6.04094C13.9442 6.04094 13.3935 6.22644 12.9427 6.56905C12.492 6.91166 12.1658 7.39252 12.0143 7.93807L10.7345 12.5352C10.1831 12.6821 9.69575 13.0069 9.34793 13.4592C9.00011 13.9115 8.81133 14.4659 8.81088 15.0365V17.6816C8.22616 17.8009 7.70057 18.1185 7.32294 18.5806C6.94532 19.0427 6.73882 19.6211 6.73834 20.2178V21.6129C5.23886 20.0825 4.22457 18.1435 3.82267 16.0389C3.42078 13.9344 3.64915 11.7581 4.47917 9.78279C5.30918 7.80753 6.70389 6.12127 8.4884 4.93548C10.2729 3.74968 12.3678 3.11712 14.5104 3.11712C16.6529 3.11712 18.7478 3.74968 20.5323 4.93548C22.3168 6.12127 23.7115 7.80753 24.5416 9.78279C25.3716 11.7581 25.6 13.9344 25.1981 16.0389C24.7962 18.1435 23.7819 20.0825 22.2824 21.6129V21.6155Z" fill="#3EAF69" />
                                </svg>
                            </a>
                            <a
                                :key="'remove-attribute-button-'+index"
                                :data-target="'#'+deleteWindowId"
                                href="javascript:void(0);"
                                class="block col-span-12 sm:col-span-5 2xl:col-span-1 z-50"
                                data-toggle="modal"
                                @click="setRemoveAttribute(item, index)"
                            >
                                <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 27.5C4.175 27.5 3.4685 27.206 2.8805 26.618C2.2925 26.03 1.999 25.324 2 24.5V5H0.5V2H8V0.5H17V2H24.5V5H23V24.5C23 25.325 22.706 26.0315 22.118 26.6195C21.53 27.2075 20.824 27.501 20 27.5H5ZM20 5H5V24.5H20V5ZM8 21.5H11V8H8V21.5ZM14 21.5H17V8H14V21.5Z" fill="#C9000E" />
                                </svg>
                            </a>
                        </div>
                        <DefaultImg
                            :alt="item.value"
                            :src="item?.media?.url"
                            :webp-src="item?.media?.thumbnail_url"
                        />
                    </div>
                </div>
                <div v-show="item.type === '4' && item.active">
                    <div class="flex justify-between w-full">
                        <a
                            :key="index"
                            href="javascript:void(0);"
                            class="block col-span-12 sm:col-span-5 2xl:col-span-1"
                            data-toggle="modal"
                            :data-target="'#update-' + item.id"
                        >
                            <svg enable-background="new 0 0 24 24" height="20" width="20" viewBox="0 0 24 24" xml:space="preserve" class="inline">
                                <path fill="#91c714" fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.5793 19.531C20.6758 17.698 22 15.0036 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 14.9616 3.28743 17.6225 5.33317 19.4535L6.99999 10.9738H9.17026L12 6.07251L14.8297 10.9738H17L18.5793 19.531ZM16.0919 21.1272L15.2056 12.9738H8.79438L7.90814 21.1272C9.15715 21.688 10.5421 22 12 22C13.4579 22 14.8428 21.688 16.0919 21.1272Z" />
                            </svg>
                        </a>
                        <a
                            :key="'remove-attribute-button-'+index"
                            href="javascript:void(0);"
                            class="block col-span-12 sm:col-span-5 2xl:col-span-1"
                            data-toggle="modal"
                            :data-target="'#'+deleteWindowId"
                            @click="setRemoveAttribute(item, index)"
                        >
                            <svg enable-background="new 0 0 24 24" height="20" width="20" viewBox="0 0 24 24" xml:space="preserve" class="inline">
                                <path fill="#CE1515" d="M22.245,4.015c0.313,0.313,0.313,0.826,0,1.139l-6.276,6.27c-0.313,0.312-0.313,0.826,0,1.14l6.273,6.272  c0.313,0.313,0.313,0.826,0,1.14l-2.285,2.277c-0.314,0.312-0.828,0.312-1.142,0l-6.271-6.271c-0.313-0.313-0.828-0.313-1.141,0  l-6.276,6.267c-0.313,0.313-0.828,0.313-1.141,0l-2.282-2.28c-0.313-0.313-0.313-0.826,0-1.14l6.278-6.269  c0.313-0.312,0.313-0.826,0-1.14L1.709,5.147c-0.314-0.313-0.314-0.827,0-1.14l2.284-2.278C4.308,1.417,4.821,1.417,5.135,1.73  L11.405,8c0.314,0.314,0.828,0.314,1.141,0.001l6.276-6.267c0.312-0.312,0.826-0.312,1.141,0L22.245,4.015z"></path>
                            </svg>
                        </a>
                    </div>
                </div>
                <div class="flex mt-5 items-center text-center justify-center">
                    <div v-if="item.color !== 'null' && itemLayerInclude.includes(type)" :style="{'backgroundColor': item.color, width: '15px', height: '15px', 'borderRadius': '50px' }" class="mr-2" />
                    <div :title="item.value" :class="['block text-center justify-center font-medium truncate max-w-[5rem]', {'text-theme-32': item.active}]">{{ item.value }}</div>
                </div>
                <div :title="setFormatPrice(item.price)" v-if="itemTypeInclude.includes(item.type)" class="block font-medium text-center truncate mt-1" style="max-width: 150px">
                    {{ store.getters['website/website']?.currency?.sign ?? '$' }}{{ setFormatPrice(item.price) }}
                    <span v-if="itemTypeSqft.includes(item.type)">/ {{ store.getters['website/metricValue'](item).toLowerCase() }}</span>
                </div>
            </div>
            <div v-if="showPlus && items.length === index+1 && !limit && !store.getters['auth/isSales']" class="plus-block" @click="clearItemChosen(item)">
                <div class="flex-none relative block before:block before:w-full rounded-lg before:pt-[100%]">
                    <div class="absolute top-0 left-0 w-full h-full image-fit p-1">
                        <a
                            style="z-index: 9998 !important;"
                            :data-target="'#modal-'+ type"
                            data-toggle="modal"
                            href="javascript:void(0);"
                            class="h-full w-full"
                        >
                            <div class="add-new zoom-in rounded-lg text-center border border-theme-32 h-full">
                                <div :class="['text-4xl h-full flex items-center justify-center', limit ? 'text-gray-600' : 'text-theme-32']">+</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </template>
        <div v-if="showPlus && items.length === 0 && !store.getters['auth/isSales']" class="plus-block" @click="clearItemChosen">
            <div class="flex-none relative block before:block before:w-full rounded-lg before:pt-[100%]">
                <div class="absolute top-0 left-0 w-full h-full image-fit p-1">
                    <a
                        style="z-index: 9998 !important;"
                        :data-target="'#modal-'+ type"
                        data-toggle="modal"
                        href="javascript:void(0);"
                        class="h-full w-full"
                    >
                        <div class="add-new zoom-in rounded-lg text-center border border-theme-32 h-full">
                            <div :class="['text-4xl h-full flex items-center justify-center', limit ? 'text-gray-600' : 'text-theme-32']">+</div>
                        </div>
                    </a>
                </div>

            </div>
        </div>
    </div>

    <div class="flex w-full justify-center mt-4 pb-4">
        <MainButton
            v-if="deselectShow"
            title="Deselect"
            @click="deselect"
        />
    </div>

    <div
        :id="'modal-'+ type"
        class="modal"
        style="z-index: 9998 !important;"
        tabindex="-1"
        aria-hidden="false"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body p-4">
                    <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal"></i>
                    <form @submit="saveAttribute">
                        <div v-if="type !== 4" class="form-group product-modal-image-wrapper">
                            <ImageUploader
                                :remove-file="removeFile"
                                @fileUploaded="fileUploaded"
                            />
                        </div>
                        <div class="form-group mt-5">
                            <FormInput
                                v-model="validate.value.$model"
                                :errors="validate.value.$errors"
                                type="text"
                                label="Name:"
                                placeholder="Enter name"
                            />
                        </div>

                        <ChooseMetricType
                            v-if="type == 1"
                            v-model="validate.metric_id.$model"
                            :errors="validate.metric_id.$errors"
                        />

                        <div v-if="type !== 4" class="form-group mt-5">
                            <FormTextarea
                                v-model="validate.description.$model"
                                :errors="validate.description.$errors"
                                type="text"
                                label="Description:"
                                placeholder="Enter description"
                            />
                        </div>
                        <div v-if="itemLayerInclude.includes(type)" class="form-group mt-5 flex items-center gap-2">
                            <InputColor
                                v-model="validate.color.$model"
                                :errors="validate.color.$errors"
                            />
                            <div>
                                Service Color <TipColor />
                            </div>
                        </div>

                        <div v-if="itemTypeInclude.includes(type)" class="form-group mt-5">
                          <!-- ***** PRICE BLOCK ***** -->
                          <div class="price-block">
                            <h3 class="underline font-semibold text-lg">Price:</h3>
                            <div class="flex items-center gap-4">
                              <div>
                                <div>
                                  <input type="checkbox"
                                         :checked="priceType == 'simple_price'"
                                         @click="onPriceTypeSelect('simple_price')"
                                         class="form-checkbox hover:cursor-pointer">
                                  {{ labelPrice }}
                                  <TipAttribute :type-attribute="type" :label="labelPrice" />
                                </div>
                              </div>
                              <div v-if="itemTypeSqft.includes(type)">
                                <MainButton
                                  title="How to calculate"
                                  size-button="small"
                                  @click="calculate.show=!calculate.show"
                                />
                              </div>
                            </div>
                            <div class="mt-2" v-if="!isTiersActive">
                              <FormInput
                                v-model.lazy="validate.price.$model"
                                :errors="validate.price.$errors"
                                :step="0.0001"
                                type="number"
                                placeholder="0.00"
                              >
                                <template #prepend>
                                  <div
                                    v-text="$store?.getters['website/website']?.currency?.sign ?? '$'"
                                    class="input-group-text"
                                  />
                                </template>
                              </FormInput>
                            </div>
                            <div v-if="calculate.show && !isTiersActive" class="mt-4">
                              <Select
                                v-model="calculate.type"
                                :options="[{name: 'Plants formula', value: 'PS'}, {name: 'Mulch/Gravel Formula', value: 'MGF'}]"
                                label="Formula"
                                placeholder="Select Formula"
                                class-map="w-full min-w-[10rem]"
                              />
                              <template v-if="calculate.type">
                                <div class="grid grid-cols-2 gap-4">
                                  <div class="mt-2">
                                    <FormInput
                                      v-model="calculate.cost"
                                      :step="0.01"
                                      :label="calculate.type === 'PS' ? 'Plan Cost' : 'Cost/yard'"
                                      type="number"
                                    />
                                  </div>
                                  <div class="mt-2">
                                    <FormInput
                                      v-model="calculate.square"
                                      :step="0.01"
                                      :label="calculate.type === 'PS' ? 'Space between plants (ft)' : 'Mulch/Gravel Depth (Inches)'"
                                      type="number"
                                    />
                                  </div>
                                </div>
                                <div class="mt-2">
                                  <template v-if="calculate.cost > 0 && calculate.square > 0">
                                            <span
                                              @click="copy(calculate.result)"
                                              class="item-copy"
                                            >
                                                {{ labelPrice }} = ${{ calculate.result }}
                                            </span>
                                  </template>
                                </div>
                              </template>
                            </div>
                            <div class="flex items-center gap-4 mt-4" v-if="!isTiersActive">
                              <div>
                                <label>
                                  Minimum
                                </label>
                              </div>
                            </div>
                            <div class="mt-2" v-if="!isTiersActive">
                              <FormInput
                                v-model.lazy="validate.min_price.$model"
                                :errors="validate.min_price.$errors"
                                :step="0.01"
                                :min="0.01"
                                type="number"
                                placeholder="0.00"
                              >
                                <template #prepend>
                                  <div
                                    v-text="$store?.getters['website/website']?.currency?.sign ?? '$'"
                                    class="input-group-text"
                                  />
                                </template>
                              </FormInput>
                            </div>
                          </div>
                          <!-- ********** END PRICE BLOCK ************ -->
                          <div v-if="props.attributeType != 5" class="space-y-4 mt-4 mb-8">
                            <div class="block" v-for="commonType in itemCommonTypes.filter(el => el.title === 'Tiers')" :key="'common-type-' + commonType.id + commonType.is_active">
                              <input type="checkbox" @click="onPriceTypeSelect('lot_size_price')" :checked="priceType === 'lot_size_price'" class="form-checkbox hover:cursor-pointer">
                              {{ commonType.title }}
                              <!-- ********** tiers block -->
                              <TiersBlock
                                v-if="priceType === 'lot_size_price'"
                                :label="labelPrice.replace('Price ', '')"
                                :optionTiers="optionTiers"
                                :price-type="commonType.config.price_type"
                                @updateTiers="updateTiers"
                                @updateTiersPriceType="updateTiersPriceType"
                              />
                              <!-- ************* -->
                            </div>
                          </div>
                          <div v-if="props.attributeType != 5" class="space-y-4 mt-4 mb-4">
                              <label class="underline font-semibold text-lg">Size Options:</label>
                              <div class="block" v-for="commonType in itemCommonTypes.filter(el => el.title !== 'Tiers')" :key="'common-type-create-' + commonType.id">
                                <!--   divider for tiers and rest  -->
                                <div class="title medium divider" v-if="commonType.title == 'Lot Size'">
                                  <span>or</span>
                                  <div class="line"></div>
                                </div>
                                <!--   end divider for tiers and rest  -->

                                <input type="checkbox" @click="onPresetsClick(commonType, $event)" :checked="commonType.is_active" class="form-checkbox hover:cursor-pointer">
                                {{ commonType.title }}
                                <!-- ********** presetsize block -->
                                <PresetBlock
                                  v-if="commonType.id == 3 && commonType.is_active"
                                  :presetSizes="presetSizes || []"
                                  :label="labelPrice.replace('Price ', '')"
                                  @updatePresetSizes="updatePresetSizes"
                                />
                                <!-- ************* -->
                              </div>
                            </div>
                          </div>
                          <div class="flex justify-center my-2">
                              <MainButton
                                  title="Save"
                                  type="submit"
                                  class-map="inline-block my-2"
                              />
                          </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, defineEmits, defineProps, onMounted, reactive, ref, watch, toRefs, inject } from 'vue'
import { maxLength, minLength, minValue, numeric, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from 'vuex';
import { setFormatPrice, copy } from "@scripts/plugins/functions";
import axios from 'axios';
import cash from 'cash-dom/dist/cash';
import TipAttribute from "@components/Tooltips/TipAttribute.vue";
import ImageUploader from '@components/UI/ImageUploader';
import TipColor from "@components/Tooltips/TipColor.vue";
import InputColor from "@components/UI/InputColor.vue";
import MainButton from "@components/Button/MainButton.vue";
import FormInput from "@components/UI/Input.vue";
import DefaultImg from "@components/UI/DefaultImg.vue";
import Select from "@components/UI/Select.vue";
import ChooseMetricType from "@components/Page/Attribute/ChooseMetricType.vue";
import FormTextarea from "@components/UI/FormTextarea.vue";
import TiersBlock from './TiersBlock.vue'
import PresetBlock from './PresetBlock.vue'
const toast = inject('toast');

const props = defineProps({
    items: {
        type: Array,
        default: () => []
    },
    currentItem: {
      type: Object,
      default: () => {
        return {
          value: '',
          description: '',
          price: null,
          min_price: null,
          color: null,
          metric_id: ''
        }
      }
    },
    type: {
        type: Number,
        default: null
    },
    deleteWindowId: {
        type: String,
        default: null
    },
    attributeType: {
        type: Number,
        required: true
    },
    limit: {
        type: Boolean,
        default: false
    },
    labelPrice: {
        type: String,
        default: 'SQFT'
    }
});
const emit = defineEmits(['itemChosen', 'itemDeselect', 'saveAttribute', 'clearItemChosen']);
const deselectShow = computed(() => props.items.some(item => item.active))
const removeAttributeData = ref({})
const store = useStore();
const removeFile = ref(false);
const formData = new FormData();
const itemTypeInclude = [ 2, 3, 5, '2', '3', '5' ];
const itemTypeSqft = [ 2, 3, '2', '3' ];
const itemLayerInclude = [1, '1'];
const priceType = ref('simple_price');
const tierPriceType = ref('flat fee')
const calculate = ref({
    show: false,
    cost: 0,
    square: 0,
    type: null,
    result: 0
});
const showPlus = ref(false);
const requestData = reactive({
    value: '',
    description: '',
    price: 0.00,
    min_price: null,
    color: '',
    metric_id: ''
});

const generalCommonTypes = store.getters['general/getAttributeCommonTypes']
const itemCommonTypes = ref(generalCommonTypes.map(type => ({
  ...type,
  is_active: [4,5].includes(type.id) ? false : true, // make false for tiers and lot size by default
  config: type.id == 3 ? { sizes: [] } : (type.id == 4 ? { tiers: [] } : {}),
  attribute_common_type_id: type.id
})))

const isTiersActive = computed(() => priceType.value === 'lot_size_price')
const rules = {
    value: { required, maxLength: maxLength(25) },
    description: { maxLength: maxLength(80) },
    price: !itemLayerInclude.includes(props.type) && !isTiersActive ? { required, numeric, minValue: minValue(0.0001), maxLength: maxLength(9) } : {},
    min_price: { minValue: minValue(0.01) },
    color: itemLayerInclude.includes(props.type) ? { required, minLength: minLength(4) } : {},
    metric_id: props.type == 1 ? { required } : {}
}
const validate = useVuelidate(rules, requestData);

const setRemoveAttribute = (attributeData, index) => {
    removeAttributeData.value = attributeData
    removeAttributeData.value.index = index
}

const removeAttribute = () => {
    axios.delete(`/api/attribute/${removeAttributeData.value.id}`, {
        params: {id: removeAttributeData.value.id}
    })

    cash('#remove-attribute-' + props.type).modal('hide')
}

const onPriceTypeSelect = (price_type) => {
  priceType.value = price_type
}

const selectItem = (item) => {
    props.items.forEach(item => item.active = 0);
    item.active = 1;

    emit('itemChosen', item);
}

const deselect = () => {
    props.items.forEach(item => item.active = 0);

    emit('itemDeselect', props.attributeType);

    validate.value.$reset();
}

const clearItemChosen = (item = null) => {
    emit('clearItemChosen');

    if (item && [ 1, 2, 3, 5, '1', '2', '3', '5' ].includes(item.type)) {
        props.items.forEach(item_ => item_.type === item.type ? item_.active = 0 : '');
    }

    requestData.color = '#000000';
    requestData.metric_id = '';
    calculate.value.show = false;
    calculate.value.result = 0;
    calculate.value.cost = 0;
    calculate.value.square = 0;
    calculate.value.type = null;
}

const fileUploaded = (file) => {
    formData.append('file', file);
    removeFile.value = false;
}

const calculateFormula = () => {
    if(calculate.value.type === 'PS') {
        calculate.value.result = calculate.value.cost / calculate.value.square;
    } else {
        calculate.value.result = calculate.value.cost / (324 / calculate.value.square);
    }

    calculate.value.result = Number(calculate.value.result.toFixed(2));
}

const presetSizes = ref([])
const optionTiers = ref([])

const updatePresetSizes = (sizes) => {
  presetSizes.value = sizes

  for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
    if (itemCommonType.id == 3) {
      itemCommonTypes.value[index].config.sizes = presetSizes.value
    }
  }
}

const updateTiersPriceType = (price_type) => {
  tierPriceType.value = price_type
  
  for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
    if (itemCommonType.id == 4) {
      itemCommonTypes.value[index] = {
        ...itemCommonTypes.value[index],
        config: {
          ...itemCommonTypes.value[index].config,
          price_type: price_type
        }
      }
    }
  }
}

const updateTiers = (tiers) => {
  optionTiers.value = tiers

  for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
    if (itemCommonType.id == 4) {
      itemCommonTypes.value[index] = {
        ...itemCommonTypes.value[index],
        config: {
          ...itemCommonTypes.value[index].config,
          tiers: optionTiers.value
        }
      }
    }
  }
}

const onPresetsClick = (common, e) => {
  for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
    if (itemCommonType.id == common.id) {
      itemCommonTypes.value[index].is_active = !itemCommonTypes.value[index].is_active
    }
  }
}

const checkSiteOptionSelectedValidation = () => {
  if (itemCommonTypes.value[0].is_active || itemCommonTypes.value[1].is_active || itemCommonTypes.value[2].is_active || itemCommonTypes.value[4].is_active) {
    return true;
  }

  return false;
}

const saveAttribute = (e) => {
  e.preventDefault();
  validate.value.$touch();

  if (!checkSiteOptionSelectedValidation()) {
    toast({
      text: 'Please select at least one size option',
      type: 'danger'
    });
    return;
  }

  if (!validate.value.$invalid) {
    itemCommonTypes.value.forEach((type, index) => {
      formData.append('attribute_common_types[]', JSON.stringify(type))
    })

    formData.append('value', requestData.value);
    formData.append('type', props.type);
    formData.append('price', requestData.price);
    formData.append('price_type', priceType.value ?? 'simple_price')

    if (requestData.min_price) {
      formData.append('min_price', requestData.min_price)
    }

    formData.append('description', requestData.description);
    formData.append('color', requestData.color);
    formData.append('metric_id', requestData.metric_id);

    cash('#modal-' + props.type).modal('hide');
    removeFile.value = true;

    emit('saveAttribute', formData);

    formData.delete('value');
    formData.delete('type');
    formData.delete('price');
    formData.delete('price_type');
    formData.delete('min_price');
    formData.delete('description');
    formData.delete('color');
    formData.delete('metric_id');
    itemCommonTypes.value.forEach((type, index) => {
      formData.delete('attribute_common_types[]', JSON.stringify(type))
    })

    requestData.value = '';
    requestData.description = '';
    requestData.metric_id = '';
    requestData.price = 0;

    itemCommonTypes.value = generalCommonTypes.map(type => ({
      ...type,
      is_active: true,
      config: type.id == 3 ? { sizes: [''] } : (type.id == 4 ? { tiers: [] } : {}),
      attribute_common_type_id: type.id
    }))

    validate.value.$reset();

    // set empty array but without losing reactivity
    setTimeout(() => {
      updatePresetSizes([])
      updateTiers([])
    }, 300)

    for (let itemCommonType of itemCommonTypes.value) {
      if (itemCommonType.id == 3) {
        itemCommonType.config.sizes = presetSizes.value
      }

      if (itemCommonType.id == 4) {
        itemCommonType.config.tiers = optionTiers.value
        itemCommonType.config.price_type = tierPriceType.value
      }
    }

    toast({
      text: 'Attribute saved successfully',
      type: 'success'
    });
  }
}

watch(
    () => calculate.value,
    (calculate) => {
        if (calculate.cost > 0 && calculate.square > 0) {
            calculateFormula();
        }
    },
    {
        deep: true
    }
);

// pre-sizes, map or measurements if true -> lot size false
watch(() => itemCommonTypes.value[0]?.is_active, (value) => {
  if (itemCommonTypes.value[0]?.is_active) {
    itemCommonTypes.value[4].is_active = false
  }
});
watch(() => itemCommonTypes.value[1]?.is_active, (value) => {
  if (itemCommonTypes.value[1]?.is_active) {
    itemCommonTypes.value[4].is_active = false
  }
});
watch(() => itemCommonTypes.value[2]?.is_active, (value) => {
  if (itemCommonTypes.value[2]?.is_active) {
    itemCommonTypes.value[4].is_active = false
  }
});

// lot size if true -> pre-sizes, map and measurements false
watch(() => itemCommonTypes.value[4]?.is_active, (value) => {
  if (itemCommonTypes.value[4]?.is_active) {
    itemCommonTypes.value[0].is_active = false
    itemCommonTypes.value[1].is_active = false
    itemCommonTypes.value[2].is_active = false
  }
});

onMounted(() => {
  setTimeout(() => {
    showPlus.value = true;
  }, 500);
});
</script>

<style lang="scss" scoped>
.attribute-item {
    padding: 0.3rem;
}

.active {
    border: 2px solid #718096;
    filter: drop-shadow(0px 4px 4px rgba(39, 174, 96, 0.1));
    backdrop-filter: blur(5px);
    padding: 0.3rem;
}

.product-modal-image-wrapper :deep(.height-img-wrapper) {
    height: 10rem;
    width: 10rem;
}

.zoom-in:hover {
    box-shadow: none !important;
}

.modal {
    z-index: 9998 !important;
}

.item-copy {
    cursor: copy;
}

.plus-block {
    width: auto;

    @media (min-width: 1200px) {
        width: 120px;
    }
}

.divider {
  position: relative;
  height: 20px;
  width: 100%;

  span {
    text-transform: uppercase;
    position: absolute;
    left: calc(50% - 17px);
    top: 0;
    z-index: 3;
    padding: 0 8px;
    background-color: white;
    color: rgba(102, 102, 102, 1);
    font-size: 12px;
    font-weight: 600;
  }

  .line {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid rgba(211, 212, 213, 1);
  }
}
</style>
