<template>
    <Tab>
        <div class="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-2 gap-4 pb-4">
            <div class="col-span-2 xl:col-span-1">
                <FormInput
                    v-model="validate.domain.$model"
                    :errors="validate.domain.$errors"
                    :isIntro="false"
                    class-map="w-full"
                    label="Domain"
                    placeholder="Enter domain"
                >
                    <template #prepend>
                        <div class="input-group-text">
                            {{ locationCustomDomain }}
                        </div>
                    </template>
                    <template #append>
                        <div class="input-group-text">
                            {{ hostnameCustomDomain }}
                        </div>
                    </template>
                </FormInput>
            </div>
            <div>
                <MainButton
                    title="Visit Site"
                    class-map="mt-0 lg:mt-[1.7rem]"
                    @click.prevent="openLanding()"
                />
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-4">
            <div>
                <FormInput
                    v-model="validatePage.title.$model"
                    :errors="validatePage.title.$errors"
                    label="Website title"
                    placeholder="Enter website title"
                    id="website-privacy-policy-title"
                >
                    <template #tip>
                        <TipWebsiteTitle />
                    </template>
                </FormInput>
            </div>
            <div v-if="unwantedSubTypes">
                <FormInput
                    v-model="validatePage.seo_description.$model"
                    :errors="validatePage.seo_description.$errors"
                    class="w-full"
                    id="website-privacy-policy-description"
                    label="SEO description"
                    placeholder="Enter seo description"
                >
                    <template #tip>
                        <TipSeo />
                    </template>
                </FormInput>
            </div>
            <div v-if="unwantedSubTypes">
                <CustomSelect
                    v-model="currency"
                    placeholder="Select type of currency"
                    label="Type of currency"
                    :options="currencies"
                />
            </div>
            <div>
                <FormInput
                    v-model="validate.title.$model"
                    :errors="validate.title.$errors"
                    class="w-full"
                    label="Company name"
                    placeholder="Enter company name"
                />
            </div>
            <template v-if="unwantedSubTypes">
                <div>
                    <FormInput
                        v-model="validate.callback.button_text.$model"
                        :errors="validate.callback.button_text.$errors"
                        label="Call to action button name"
                        placeholder="Enter button name"
                    />
                </div>
                <div>
                    <FormInput
                        v-model="validate.callback.button_link.$model"
                        :errors="validate.callback.button_link.$errors"
                        label="Call to action button link"
                        placeholder="Enter button link"
                    />
                </div>
            </template>
            <div>
                <FormInput
                    v-model="validateContactUs.email.$model"
                    :errors="validateContactUs.email.$errors"
                    type="email"
                    label="Contact Us Email"
                    placeholder="Enter contact us email"
                >
                    <template #prepend>
                        <div
                            class="input-group-text"
                            v-text="'@'"
                        />
                    </template>
                    <template #tip>
                        <TooltipContactUs />
                    </template>
                </FormInput>
            </div>
          <div>
            <FormInput
              v-model="validate.google_tag.$model"
              :errors="validate.google_tag.$errors"
              class="w-full"
              label="Google Analytic Tag ID"
              placeholder="Enter Google Analytic Tag"
            >
                <template #tip>
                    <TooltipGoogleTag />
                </template>
            </FormInput>    
          </div>
          <div>
            <FormInput
              v-model="validate.fb_pixel.$model"
              :errors="validate.fb_pixel.$errors"
              class="w-full"
              label="Facebook Pixel ID"
              placeholder="Enter Facebook Pixel"
            >
                <template #tip>
                    <TooltipFBPixelTag />
                </template>
            </FormInput>
          </div>
          <div>
            <FormTextarea
              v-model="validate.how_to_use_video_embed_code.$model"
              :errors="validate.how_to_use_video_embed_code.$errors"
              class="w-full"
              label="How to use (your video embed code)"
              placeholder="Paste video embed code"
            >
                <template #tip>
                    <TooltipHowToUse />
                </template>
            </FormTextarea>
          </div>
          <div>
            <FormTextarea
              v-model="validate.quote_notes.$model"
              :errors="validate.quote_notes.$errors"
              class="w-full"
              label="Quote Note"
              placeholder="Quote Note"
            >
            </FormTextarea>
          </div>
        </div>
        <div class="flex justify-center w-full mt-5">
            <MainButton
                :type-button="processing ? 'disabled' : ''"
                title="Save"
                @click="save"
            />
        </div>
        <Line />
        <div class="mt-4">
            <div class="grid grid-cols-3">
                <div class="col-span-3">
                    <Title title="Site Logo" type-title="small" class="!my-0 !text-center" />
                    <ImageUploader
                        :url="data.logo"
                        class="image-uploader !pt-2"
                        @fileUploaded="fileUploaded"
                    />
                </div>
            </div>
            <Line />
        </div>
    </Tab>
</template>

<script setup>
import { computed, getCurrentInstance, inject, onMounted, reactive, ref, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import { usePhoneMask } from "@mixins/composition/phoneMask";
import { email, helpers, maxLength, required, requiredIf } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import axios from 'axios';
import Tab from './Tab.vue';
import ImageUploader from '@components/UI/ImageUploader';
import TipWebsiteTitle from "@components/Tooltips/GeneralTab/TipWebsiteTitle.vue";
import TooltipContactUs from "@components/Tooltips/TooltipContactUs.vue";
import TooltipGoogleTag from "@components/Tooltips/TooltipGoogleTag.vue";
import TooltipFBPixelTag from "@components/Tooltips/TooltipFBPixelTag.vue";
import TooltipHowToUse from "@components/Tooltips/TooltipHowToUse.vue";
import TipSeo from "@components/Tooltips/GeneralTab/TipSeo.vue";
import MainButton from "@components/Button/MainButton.vue";
import Title from "@components/Title.vue";
import FormInput from "@components/UI/Input.vue";
import FormTextarea from "@components/UI/FormTextarea.vue";
import Line from "@components/Line.vue";
import CustomSelect from "@components/UI/CustomSelect.vue";


const store = useStore();
const { proxy } = getCurrentInstance();
const mediaPreview = ref(null);
const currencies = ref([]);
const currency = ref('');
const processing = ref(false);
const defaultVal = ref('');
const locationCustomDomain = ref(window.location.protocol + '//');
const hostnameCustomDomain = ref('.' + window.location.hostname);
const regex = (value) => /^[^./|\\]+$/.test(value);
const websiteId = computed(() => store.getters['website/websiteId']);
const website = computed(() => store.getters['website/website']);
const unwantedSubTypes = computed(() => store.getters['auth/unwantedSubTypes']);
const toast = inject('toast');
const mainPage = computed({
    get() {
        return store.getters['website/mainPage'];
    },
    set(value) {
        store.commit('website/setMainPage', value);
    }
});
const data = reactive({
    onlyQuote: false,
    logo: '',
    domain: '',
    title: '',
    google_tag: '',
    fb_pixel: '',
    how_to_use_video_embed_code: '',
    quote_notes: '',
    callback: {
        button_link: '',
        button_text: ''
    },
});
const callback = computed(() => data.callback);
const customValidation = (value) => {
    if (callback.value.button_link == null && callback.value.button_text == null) {
        return true;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    return emailRegex.test(value) || urlRegex.test(value);
}
const data_v = {
    domain: { required, matchRegex: helpers.withMessage('Domain must not contain special symbols', regex) },
    title: { required },
    google_tag: {},
    fb_pixel: {},
    how_to_use_video_embed_code: {},
    quote_notes: {},
    onlyQuote: { required },
    callback: unwantedSubTypes.value ? {
        button_link: {
            required: requiredIf(() => callback.value.button_link != null && callback.value.button_text.length > 0),
            withParams: helpers.withParams({ type: 'email or URL' }, value => customValidation(value)),
            withMessage: helpers.withMessage('The button link must be either an email address or a URL.', customValidation)
        },
        button_text: { required: requiredIf(() => callback.value.button_text != null && callback.value.button_link.length > 0), maxLength: maxLength(40) },
    } : {},
}
const validate = useVuelidate(data_v, toRefs(data));
const dataContactUs = reactive({
    email: '',
});
const contactUsRules = {
    email: { required, email }
}
const validateContactUs = useVuelidate(contactUsRules, toRefs(dataContactUs));
const dataPage = reactive({
    title: '',
    seo_description: '',
});
const pageRules = {
    title: { required,},
    seo_description: { maxLength: maxLength(200) },
}
const validatePage = useVuelidate(pageRules, toRefs(dataPage));
const statuses = computed(() => store.getters['website/statuses']);
const loading = computed(() => store.getters['main/loading']);
const footer = computed(() => store.getters['website/footer']);
const onlyQuote = computed({
    get() {
        return store.getters['website/onlyQuote']
    },
    set(value) {
        store.commit('website/setOnlyQuote', value);
    }
});
const contactUsStore = computed({
    get() {
        return store.getters['website/mainPageContactUs'];
    },
    set(value) {
        store.commit('website/setMainPageContactUs', value);
    }
});
const websiteSettings = computed({
    get() {
        return store.getters['website/websiteSettings']
    },
    set(value) {
        store.commit('website/setWebsiteSettings', value)
    }
});
const status = computed({
    get() {
        return store.getters['website/status']
    },
    set(value) {
        store.commit('website/setStatus', value)
    }
});
const logo = computed({
    get() {
        return store.getters['website/logo'];
    },
    set(value) {
        store.commit('website/setLogo', value);
    }

});


const openLending = () => {
    return validate.value?.domain.$model ? `${ window.location.protocol }//${ validate.value.domain.$model }.${ window.location.hostname }` + '/quote?preview=true' : '';
}

function isObjectNotEmpty(obj) {
    const keys = Object.keys(obj);

    return keys.every(key => {
        const value = obj[key];
        return value !== null && value !== undefined && value !== '';
    });
}

function isNotEmpty(obj) {
    return obj !== null && obj !== undefined && obj !== '';
}



const openLanding = () => {
    let settings = store.getters['website/websiteSettings'];
    let emailSettings = store.getters['website/getEmailSettings'];

    if (unwantedSubTypes.value) {
        if (
            !settings.onlyQuote && (
                !settings.hasOwnProperty("about_us")
                || !settings.hasOwnProperty("contact_us")
                || !isNotEmpty(settings.about_us.title)
                || !isNotEmpty(settings.about_us.description)
                || !isObjectNotEmpty(settings.contact_us))
        )
        {
            return toast({
                type: 'danger',
                text: 'Please fill in the data on the "Main Page"'
            });
        }

        if (
            emailSettings
            &&
            (
                !emailSettings.hasOwnProperty("mailInfo")
                || !emailSettings.hasOwnProperty("companyInfo")
                || !isObjectNotEmpty(emailSettings.companyInfo)
                || !isObjectNotEmpty(emailSettings.mailInfo)
            )
        )
        {
            return toast({
                type: 'danger',
                text: 'Please fill in the data on the "Email settings"'
            });
        }
    }

    return window.open(openLending(), '_blank');
}

const fileUploaded = async (file) => {
    mediaPreview.value = file;

    const settings = {
        websiteSettings: JSON.parse(JSON.stringify(websiteSettings.value))
    }

    if (mediaPreview.value) {
        const formData = new FormData();
        formData.append('file', mediaPreview.value);

        const { data: { media: { url } } } = await store.dispatch('main/media', formData);
        settings.websiteSettings.logo = url;
        logo.value = url;
    }

    await store.dispatch('website/settings', {
        settings,
        website_id: websiteId.value,
        type: 'General'
    });

    store.commit('website/setLogo', settings.websiteSettings.logo)

    websiteSettings.value = settings.websiteSettings

    toast({
        type: 'success',
        text: 'Site logo successfully updated.'
    });
}
const save = async () => {
    validate.value.$touch();
    validateContactUs.value.$touch();
    validatePage.value.$touch();

    if (!validate.value.$invalid && !validatePage.value.$invalid && !validateContactUs.value.$invalid) {
        try {
            await store.dispatch('website/updateWebsite', {
                code: validate.value.domain.$model,
                company_name: validate.value.title.$model,
                status: status.value,
                currency: currency.value,
                user_id: store?.getters['auth/user']?.id,
                google_tag: validate.value.google_tag.$model,
                fb_pixel: validate.value.fb_pixel.$model,
                how_to_use_video_embed_code: validate.value.how_to_use_video_embed_code.$model,
                quote_notes: validate.value.quote_notes.$model,
            });

            store.commit('website/updateWebsite', {
                code: validate.value.domain.$model,
                company_name: validate.value.title.$model,
                currency: currencies.value.filter(e => e.value === currency.value)[0],
            });

            mainPage.value.title = dataPage.title;
            mainPage.value.seo_description = dataPage.seo_description;
            await store.dispatch('website/page', mainPage.value);
            websiteSettings.value.callback = data.callback;
            websiteSettings.value.contact_us.email = dataContactUs.email;
            websiteSettings.value.google_tag = data.google_tag;
            websiteSettings.value.fb_pixel = data.fb_pixel;
            websiteSettings.value.how_to_use_video_embed_code = data.how_to_use_video_embed_code;
            websiteSettings.value.quote_notes = data.quote_notes;

            const settings = {
                websiteSettings: JSON.parse(JSON.stringify(websiteSettings.value))
            }

            await store.dispatch('website/settings', {
                settings,
                website_id: websiteId.value
            });

            websiteSettings.value = settings.websiteSettings;

            toast({
                type: 'success',
                text: 'Website settings successfully updated.'
            });

        } catch (e) {
            proxy.$errorHandler(e);
        }
    }
}

const getGeneralData = () => {
    const { logo, footer, callback, google_tag, fb_pixel, how_to_use_video_embed_code, quote_notes } = website.value ?? {};
    const { email } = contactUsStore.value ?? {};
    const { title, seo_description } = mainPage.value ?? {};
    const { code, company_name } = website.value ?? {};

    data.logo = logo ?? '';
    defaultVal.value = footer?.contact_phone ?? '';
    data.title = company_name;
    data.domain = code;
    data.google_tag = google_tag;
    data.fb_pixel = fb_pixel;
    data.how_to_use_video_embed_code = how_to_use_video_embed_code;
    data.quote_notes = quote_notes;
    data.callback.button_link = callback?.button_link;
    data.callback.button_text = callback?.button_text;

    dataContactUs.email = email;

    dataPage.title = title
    dataPage.seo_description = seo_description;
}

const changeVisibility = () => {
    const items = document.getElementsByClassName('status-option');

    for (const item of items) {
        let selected = item.getAttribute('selected');

        if (selected) {
            status.value = item.getAttribute('value');
        }
    }
}


watch(
    () => websiteSettings.value,
    (data) => {
        onlyQuote.value = data.onlyQuote;
    },
    {
        deep: true
    }
);

onMounted(async () => {
    usePhoneMask('#website-header-phone-input');

    currencies.value = await axios.get('/api/currency').then(({ data }) => data);
    currency.value = store?.getters['website/website']?.currency?.value ?? '$';

    getGeneralData();
});
</script>

<style lang="scss" scoped>
.site-button {
    @media (min-width: 640px) {
        width: calc(100px + 5%);
    }
    @media (min-width: 1024px) {
        width: 85px;
    }
    @media (min-width: 1144px) {
        width: calc(100px + 5%);
    }
    @media (min-width: 1280px) {
        width: calc(100px + 10%);
    }
    @media (min-width: 1536px) {
        width: calc(150px + 20%);
    }
}
.grid-cols-4 {
    grid-template-columns: repeat(4, 1fr);
}

.vc-color-wrap {
    width: 100% !important;
    height: 100% !important;
}
:deep(.height-img-wrapper){
    height: 10rem;
    width: 10rem;
}
.image-uploader{
    button {
        max-width: 206px!important;
    }
}
</style>
