import { pick } from "lodash";
import axios from 'axios';

function filterFields(objects, fields) {
    if (objects.length < 1) {
        return objects;
    }

    return objects.map(obj => {
        const filteredObj = {};

        fields.forEach(field => {
            if (obj.hasOwnProperty(field)) {
                filteredObj[field] = obj[field];
            }
        });

        return filteredObj;
    });
}

export default {
    async getWebsite(context, code) {
        await axios
            .post('/api/website-search', { code })
            .then(({ data }) => {
                this.commit('quote/setQuoteWebsite', data);
                this.commit('quote/setButtonSettings', data?.website?.settings);
                this.commit('auth/setUser', data.user);
            })
            .catch((error) => {

            });
    },
    getAttributes(context, payload) {
        axios
            .get(`/api/get-layers/${ payload.id }`)
            .then((response) => {
                const { data } = response;
                let mutationData = data;

                if (data[0] && data[0].children) {
                    mutationData = data[0].children;
                }

                this.commit('quote/' + payload.mutationName, mutationData);
            }).catch(({ response }) => {
                if (response.status === 402 && response.data.message === 'Subscription has been ended!') {
                    this.commit('auth/setTrialPlanIsExpired', true);
                }
            });
    },
    setRanges(context, payload) {
        axios
            .get('/api/get-ranges')
            .then((response) => {
                const { data } = response
                this.commit('quote/setRanges', data)
            });
    },
    setSimpleUnitItems(context, payload) {
        axios
            .get('/api/get-simple-unit-items', { params: { types: [ 5 ] } })
            .then((response) => {
                const { data } = response;
                this.commit('quote/setUnitItems', data);
            });
    },
    async uploadMedia(context, payload) {
        await axios.post('/api/media', payload)
            .then((response) => {
                const { data } = response
                this.commit('quote/imageData', data.media)
            });
    },
    getUpgrades(context, payload) {
        axios.get(`/api/get-upgrades/${ payload.id }`)
            .then((response) => {
                const { data } = response
                let mutationData = data

                if (data[0].children) {
                    mutationData = data[0].children
                }

                this.commit('quote/setUpgrades', mutationData)
            });
    },
    setSummary(context, { isCompleted }) {
        if (!isCompleted) isCompleted = false
        return new Promise((resolve, reject) => {
            let selectedUpgrades = filterFields(this.getters['quote/selectedUpgrades'], [ 'id', 'value', 'pivot', 'type', 'price' ]);
            let selectedOptions = filterFields(this.getters['quote/selectedOptions'], [ 'id', 'value', 'pivot', 'type', 'price' ]);
            let selectedLayers = this.getters['quote/selectedLayers'];
            let selectedSimpleUnitItems = filterFields(this.getters['quote/selectedSingleUnitItems'], [ 'id', 'value', 'quantity', 'type', 'price', 'parents' ]);
            let ranges = this.getters['quote/getRanges'];
            const website = this.getters['quote/website'];

            axios
                .post('/api/calculate', {
                    upgrades: selectedUpgrades,
                    options: selectedOptions,
                    layers: selectedLayers,
                    simpleUnitItems: selectedSimpleUnitItems,
                    ranges: ranges,
                    customer: pick(
                        this.getters['quote/getUserData'],
                        [ 'name', 'email', 'phone', 'address', 'city' ]
                    ),
                    currency: website?.currency?.sign ?? '$',
                    imageData: this.getters['quote/getImageData'],
                    isCompleted: isCompleted
                })
                .then((response) => {
                    const { data } = response;
                    this.commit('quote/setSummaryData', data);
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    changeStepTransform(context, { next }) {
        let transformData = this.getters['quote/getTransformData'];
        let stepTransform = next ? transformData.stepTransform - transformData.indent : transformData.stepTransform + transformData.indent;
        this.commit('quote/setStepTransform', stepTransform);
    },
    newQuote() {
        let transformData = this.getters['quote/getTransformData'];

        this.commit('quote/setStepTransform', 0 - transformData.indent);
    },
    async getCustomerTier(context) {
      const address = this.getters['quote/getUserData'].address
      try {
        const response = await axios.get('/api/get-lot-size/' + address)
        if (response?.data) {
          this.commit('quote/setCustomerTier', response.data.data)
        }
      } catch (e) {
        console.error(e)
        this.commit('quote/setCustomerTier', { lot_sqft: 0 })
      }
    }
}
