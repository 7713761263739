<script setup>
import PreSetSizeModal from './PreSetSizeModal.vue'
import MainButton from '../../../Components/Button/MainButton.vue'
import cash from 'cash-dom/dist/cash'
import { onMounted, ref, watch } from 'vue'

const generateId = () => {
  return (
    "_" +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
}
const idPreSetModal = 'preSetSizeAdminModal' + generateId()

const emits = defineEmits(['updatePresetSizes'])
const props = defineProps({
  presetSizes: {
    type: Array
  },
  label: {
    type: String
  }
})

const presets = ref([])
const currentSize = ref({
  id: 0,
  title: '',
  size: 0.00,
  image: null,
  isUpdate: false
})
onMounted(() => {
  presets.value = props.presetSizes
})

watch(() => presets.value, (value) => {
  emits('updatePresetSizes', presets.value)
}, {
  deep: true
})

watch(() => props.presetSizes, (value) => {
  presets.value = value
}, {
  deep: true
})

const showModal = (id, itemId = 0) => {
  if (id == '#' + idPreSetModal) {
    if (itemId != 0) {
      const newSize = JSON.parse(JSON.stringify(presets.value.filter(el => el.id == itemId)))[0]
      currentSize.value.id = newSize.id
      currentSize.value.title = newSize.title
      currentSize.value.size = newSize.size
      currentSize.value.image = newSize.image
    } else {
      currentSize.value.id = 0
      currentSize.value.title = ''
      currentSize.value.size = 0
      currentSize.value.image = null
    }
  }

  cash(id).modal('show')
}



const deletePresetSize = (size) => {
  presets.value = presets.value.filter(el => el.id !== size.id)
}

const preSetSize = (data) => {
  presets.value = data
}
</script>

<template>
  <div class="mt-2">
    <div v-for="(size) in presetSizes" :key="'presize-' + size" class="flex mt-2 mb-2 w-full justify-center gap-4 items-center">
      <div class="text-base font-medium" style="width: 100px;">{{ size.title }}</div>
      <!-- actions -->
      <div class="flex items-center h-full justify-evenly gap-4">
        <a @click.prevent.stop="showModal('#' + idPreSetModal, size?.id ?? 0)" class="hover:cursor-pointer block col-span-12 sm:col-span-5 2xl:col-span-1 z-50" data-toggle="modal">
          <svg width="30" height="30" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.4029 4.10388C22.1132 1.81267 19.0999 0.386302 15.8764 0.0678461C12.6529 -0.25061 9.41876 0.558548 6.72503 2.35743C4.03129 4.15632 2.04468 6.83362 1.10373 9.9331C0.162769 13.0326 0.325694 16.3624 1.56474 19.3553C2.80378 22.3481 5.04227 24.8186 7.89875 26.346C10.7552 27.8733 14.0529 28.3629 17.2299 27.7312C20.4069 27.0996 23.2666 25.3859 25.3216 22.8821C27.3767 20.3783 28.4999 17.2394 28.5 14.0002C28.4999 10.2888 27.0262 6.72925 24.4029 4.10388ZM11.9197 15.5546H17.101V17.6272H11.9197V15.5546ZM9.84715 23.8344V20.736H19.1736V23.8318C17.7156 24.5206 16.1232 24.878 14.5107 24.8785C12.8981 24.8789 11.3055 24.5224 9.84715 23.8344ZM22.2824 21.6155V20.2178C22.2825 19.6206 22.0763 19.0417 21.6986 18.579C21.3209 18.1164 20.795 17.7984 20.2098 17.679V15.0365C20.2094 14.4659 20.0206 13.9115 19.6728 13.4592C19.325 13.0069 18.8376 12.6821 18.2863 12.5352L17.0065 7.93807C16.8549 7.39252 16.5288 6.91166 16.078 6.56905C15.6272 6.22644 15.0766 6.04094 14.5104 6.04094C13.9442 6.04094 13.3935 6.22644 12.9427 6.56905C12.492 6.91166 12.1658 7.39252 12.0143 7.93807L10.7345 12.5352C10.1831 12.6821 9.69575 13.0069 9.34793 13.4592C9.00011 13.9115 8.81133 14.4659 8.81088 15.0365V17.6816C8.22616 17.8009 7.70057 18.1185 7.32294 18.5806C6.94532 19.0427 6.73882 19.6211 6.73834 20.2178V21.6129C5.23886 20.0825 4.22457 18.1435 3.82267 16.0389C3.42078 13.9344 3.64915 11.7581 4.47917 9.78279C5.30918 7.80753 6.70389 6.12127 8.4884 4.93548C10.2729 3.74968 12.3678 3.11712 14.5104 3.11712C16.6529 3.11712 18.7478 3.74968 20.5323 4.93548C22.3168 6.12127 23.7115 7.80753 24.5416 9.78279C25.3716 11.7581 25.6 13.9344 25.1981 16.0389C24.7962 18.1435 23.7819 20.0825 22.2824 21.6129V21.6155Z" fill="#3EAF69"></path>
          </svg>
        </a>
        <a @click.prevent.stop="deletePresetSize(size)" class="hover:cursor-pointer block col-span-12 sm:col-span-5 2xl:col-span-1 z-50" data-toggle="modal">
          <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 27.5C4.175 27.5 3.4685 27.206 2.8805 26.618C2.2925 26.03 1.999 25.324 2 24.5V5H0.5V2H8V0.5H17V2H24.5V5H23V24.5C23 25.325 22.706 26.0315 22.118 26.6195C21.53 27.2075 20.824 27.501 20 27.5H5ZM20 5H5V24.5H20V5ZM8 21.5H11V8H8V21.5ZM14 21.5H17V8H14V21.5Z" fill="#C9000E"></path>
          </svg>
        </a>
      </div>
    </div>
    <div class="flex w-full items-center h-full justify-evenly mb-4">
      <MainButton
        title="Add a preset size"
        size-button="small"
        @click="showModal('#' + idPreSetModal)"
      />
    </div>

    <PreSetSizeModal
      :optionPreSizes="presets"
      :currentSize="currentSize"
      :units="label"
      @preSetSize="preSetSize"
      :id="idPreSetModal"
    />
  </div>
</template>

<style scoped>
  
</style>
