<template>
    <GeneralLayout>
        <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-[3rem] gap-y-[5rem] h-100 py-4">
            <div
                v-for="(plan, index) in plans"
                :key="index"
            >
                <PlanItem
                    v-if="plan.type !== 2"
                    :plan="plan"
                    :plans-length="plans.length"
                    :has-trial="user.availableTrial"
                    :current-plan="currentPlan"
                    style="height: 100%"
                >
                    <template #button>
                        <MainButton
                            :type-button="plan.priceId === priceId ? 'disabled' : ''"
                            :title="plan.priceId === priceId ? 'Current Plan' : 'Select Plan'"
                            @click="updatePlan(plan)"
                        />
                    </template>
                </PlanItem>
            </div>
        </div>
        <div
            id="updatePlanTimeModal"
            class="modal"
            tabindex="-1"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body p-4 flex flex-col">
                        <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal"></i>

                        <div class="flex justify-center mb-4">
                            <img :src="updatePlanImg" alt="" width="120" height="120">
                        </div>

                        <div v-if="chosenPlan">
                            <div>
                                <div class="text-center text-2xl font-semibold">
                                    Plan Review
                                </div>

                                <div class="flex justify-center mt-2">
                                    <div class="text-base font-medium mb-4">
                                        <div>Upgrade to {{ chosenPlan.name }}</div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="!showAddPaymentMethod">
                                <MainBlock>
                                    <template #body>
                                        <div style="margin-left: 1.4rem">
                                            <div>
                                                <div class="grid grid-cols-3">
                                                    <div class="flex items-center gap-4 col-span-2">
                                                        <div class="circle" />
                                                        Current plan {{ currentPlan.name }}:
                                                    </div>
                                                    <div>
                                                        ${{ currentPlan.price }}
                                                    </div>
                                                </div>

                                                <div class="grid grid-cols-3">
                                                    <div class="flex items-center gap-4 col-span-2">
                                                        <div class="circle" />
                                                        New plan {{ chosenPlan.name }}:
                                                    </div>
                                                    <div>
                                                        ${{ chosenPlan.price }}
                                                    </div>
                                                </div>

                                                <div class="grid grid-cols-3">
                                                    <div class="flex items-center gap-4 col-span-2">
                                                        <div class="circle" />
                                                        Today's Total:
                                                    </div>
                                                    <div>
                                                        ${{ chosenPlan.price }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="my-4">
                                                Your subscription will auto-renew on
                                                {{ dayjs().add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD') }}
                                            </div>

                                            <div
                                                v-if="(user.hasTrial && currentPlan.planType === TYPE_PLAN_BASIC && chosenPlan.planType === TYPE_PLAN_PREMIUM)
                                            || (user.hasTrial && currentPlan.planType > chosenPlan.planType)
                                            || (chosenPlan.isTrial && (currentPlan.planType === TYPE_PLAN_MEASURE_BASIC || currentPlan.planType === TYPE_PLAN_MEASURE_PRO))"
                                                class="rounded-lg text-center bg-theme-45 py-2 border border-theme-46"
                                                style="background-color: #E9FCF0"
                                            >
                                                <div
                                                    v-if="user.availableTrial && user.hasTrial && currentPlan.planType === TYPE_PLAN_BASIC && chosenPlan.planType === TYPE_PLAN_PREMIUM"
                                                    class="px-3"
                                                >
                                                    Please note that due to your previous use of the Quote Core trial, your
                                                    trial period for Quote Pro will be shortened to 7 days instead of the
                                                    standard 30 days. This is to ensure fairness to all users.
                                                </div>
                                                <div
                                                    v-if="user.hasTrial && currentPlan.planType > chosenPlan.planType"
                                                    class="px-3"
                                                >
                                                    Please be aware that if you choose to downgrade from either the Quote
                                                    Lite or Quote Pro trial to another plan, you will permanently lose your
                                                    trial benefits. Even if you decide to return to a plan with a trial
                                                    later, it will not be reinstated.
                                                </div>
                                                <div
                                                    v-if="chosenPlan.isTrial && (currentPlan.planType === TYPE_PLAN_MEASURE_BASIC || currentPlan.planType === TYPE_PLAN_MEASURE_PRO)"
                                                    class="px-3"
                                                >
                                                    During your trial period, you will have access to all the enhanced
                                                    features and benefits of the upgraded plan. However, please note that at
                                                    the end of the trial, you will be automatically charged the default
                                                    price of the plan.
                                                </div>
                                            </div>

                                            <div class="flex justify-center pt-2">
                                                Modal closed through {{ timeAwaiting }}
                                            </div>
                                        </div>
                                    </template>
                                </MainBlock>

                                <div class="flex flex-col justify-center items-center gap-4 mt-4">
                                    <MainButton
                                        :type-button="disableButton ? 'disabled' : ''"
                                        title="Confirm plan change"
                                        @click="confirmAction(true)"
                                    />
                                </div>
                            </div>

                            <div v-show="showAddPaymentMethod">
                                <fieldset class="px-5 py-8">
                                    <FormInput
                                        v-model="cardHolderName"
                                        id="card-holder-name"
                                        placeholder="Holder Name"
                                    />
                                    <div
                                        id="card-element"
                                        class="mt-3 intro-x login__input form-control py-3 px-4 border-gray-300 block"
                                    ></div>
                                    <div class="flex justify-center">
                                        <MainButton
                                            :type-button="disableButton ? 'disabled' : ''"
                                            id="submit"
                                            class="mt-3 button py-3 px-4 align-top"
                                            @click="addPaymentMethod"
                                        >
                                            <template #body>
                                                <div className="spinner hidden" id="spinner"></div>
                                                <span id="button-text">Pay</span>
                                            </template>
                                        </MainButton>
                                    </div>
                                    <div id="payment-message" className="hidden"></div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </GeneralLayout>
</template>

<script setup>
import { computed, inject, nextTick, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { TYPE_PLAN_BASIC, TYPE_PLAN_PREMIUM, TYPE_PLAN_MEASURE_BASIC, TYPE_PLAN_MEASURE_PRO } from "@scripts/constants/planTypes";
import axios from "axios";
import MainButton from "@components/Button/MainButton.vue";
import MainBlock from "@components/MainBlock.vue";
import cash from "cash-dom/dist/cash";
import dayjs from "dayjs";
import FormInput from "@components/UI/Input.vue";
import updatePlanImg from "@images/svg/update_plan.svg";
import PlanItem from "./PlanItem.vue";
import GeneralLayout from "../../Layouts/GeneralLayout.vue";


const store = useStore();
const plans = ref([]);
const user = computed(() => store.getters['auth/user']);
const isPaymentMethod = ref(user.value.isPaymentMethod);
const stripe = Stripe(process.env.MIX_STRIPE_KEY);
const toast = inject('toast');
const maxSeconds = 60;
const timeAwaiting = ref('');
const priceId = ref('');
const cardHolderName = ref('');
const endDate = ref(null);
const chosenPlan = ref(null);
const currentPlan = ref(null);
const invoiceData = ref('');
const cardElement = ref(null);
const disableButton = ref(false);
const showAddPaymentMethod = ref(false);
const appearance = {
    theme: 'stripe',
};


const updateTimerText = (timer) => {
    if (timer >= 0) {
        let minutes = parseInt(timer / 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;

        let seconds = parseInt(timer % 60, 10);

        seconds = seconds < 10 ? "0" + seconds : seconds;

        return minutes + ":" + seconds;
    }

    return "00:00";
}

const updateSub = () => {
    disableButton.value = true;

    axios
        .put(`/api/update-plan/${chosenPlan.value.priceId}`).then((response) => {

            if (response.data.checkout_url) {
                window.location.href = response.data.checkout_url;
                return;
            }

        toast({
            type: 'success',
            text: response.data.message
        });

        getSubscriptions();

        cash('#updatePlanTimeModal').modal('hide');

        store.commit('sideMenu/resetState');
        store.commit('auth/setUser', response.data.user);

        chosenPlan.value = null;

        window.location.reload();
    })
        .catch((e) => {
            toast({
                type: 'danger',
                text: e.response.data.message
            })
        })
        .finally(() => {
            disableButton.value = false;
        });
}

const getSubscriptions = async () => {
    await axios
        .get('/api/get-plans')
        .then((response) => {
            plans.value = response.data.subscriptions;
            currentPlan.value = plans.value.filter(plan => plan.priceId === response.data.priceId);
            
            priceId.value = response.data.priceId;
            isPaymentMethod.value = response.data.isPaymentMethod;

            if (currentPlan.value[0]) {
                currentPlan.value = currentPlan.value[0];
            }
        });
}

const startProgressTimer = () => {
    let timeLeftS = endDate.value ? (endDate.value.getTime() - new Date().getTime()) / 1000 : maxSeconds;

    timeAwaiting.value = updateTimerText(timeLeftS);

    setTimeout(startProgressTimer, 500);
}

const addPaymentMethod = async (e) => {
    disableButton.value = true;

    const { setupIntent, error } = await stripe.confirmCardSetup(invoiceData.value, {
            payment_method: {
                card: cardElement.value,
                billing_details: { name: cardHolderName.value }
            }
        }
    );

    if (!error) {
        await axios
            .post('/api/v1/user/set-default-payment-method')
            .then((response) => {
                updateSub();
            })
            .catch((e) => {
                toast({ type: 'danger', text: e.response });
            });
    } else {
        toast({
            type: 'warning',
            text: error.message
        });

        disableButton.value = false;
    }
}

const setSubscribe = async () => {
    await axios
        .get('/api/v1/user/get-setup-intent')
        .then((response) => {
            invoiceData.value = response.data;
            const elements = stripe.elements();

            cardElement.value = elements.create('card', { style: { base: { color: '#232a3b' } } });
            elements.update({ locale: 'en' });
            cardElement.value.mount('#card-element');

            showAddPaymentMethod.value = true;
        })
        .catch((e) => {
            toast({ type: 'danger', text: e.response });
        });
}

const confirmAction = (confirm) => {
    updateSub();
    // if (!isPaymentMethod.value && chosenPlan.value.price > 0) {
    //     setSubscribe();
    // } else {
    //     if (confirm) {
    //         updateSub();
    //     } else {
    //         cash('#updatePlanTimeModal').modal('hide');
    //     }
    // }
}


const updatePlan = (plan) => {
    showAddPaymentMethod.value = false;
    invoiceData.value = '';
    cardHolderName.value = '';

    cash('#updatePlanTimeModal').modal('show');

    let newEnd = new Date();
    newEnd.setSeconds(newEnd.getSeconds() + maxSeconds);
    endDate.value = newEnd;

    startProgressTimer();

    chosenPlan.value = plan;
}


watch(
    () => timeAwaiting.value,
    (time) => {
        if (time === '00:00' && !showAddPaymentMethod.value) {
            cash('#updatePlanTimeModal').modal('hide');
        }
    }
);

onMounted(async () => {
    await getSubscriptions();

    await nextTick(() => {
        let cardBody = document.querySelectorAll('.card-body');

        let maxHeightBody = 0;

        cardBody.forEach(function (card) {
            if (card.offsetHeight > maxHeightBody) {
                maxHeightBody = card.offsetHeight;
            }
        });

        cardBody.forEach(function (card) {
            let sub = maxHeightBody - card.clientHeight;
            card.style.height = maxHeightBody + 'px';
            card.querySelector('.button-block').style.paddingTop = sub + 'px';
        });
    });
});
</script>

<style lang="scss" scoped>
.information {
    list-style-type: disc;

    li::marker {
        color: #3EAF69;
    }
}
</style>
